import axios from "axios";

const assets = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API + "assets",
});

export const assetsAPI = {
  getAllAssets: (userId: number) => {
    return assets.get(`/${userId}`);
  },
  getPositions: (
    userId: number,
    initDataRaw: any,
    assetType: "open" | "close"
  ) => {
    return assets.get(`/stats/${userId}?asset_type=${assetType}`, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  getHistory: (userId: number, initDataRaw: any) => {
    return axios.get(
      `${process.env.REACT_APP_PUBLIC_API}history/users/${userId}`,
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getTopUsers: (userLvl: number) => {
    return assets.get(
      `${process.env.REACT_APP_PUBLIC_API}users/${userLvl}/top_users`
    );
  },
  getUserPosition: (userId: number, userLvl: number, initDataRaw: any) => {
    return assets.get(
      `${process.env.REACT_APP_PUBLIC_API}users/user_position/${userId}?lvl=${userLvl}`,
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
};
