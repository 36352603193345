import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { userAPI } from "../../../api/user";
import {
  getBuyCountValue,
  getTotalPrice,
} from "../../../functions/getTotalPrice";
import { useUpdatePositions } from "../../../hooks/useUpdatePositions";
import { useUpdateHistory } from "../../../hooks/useUpdateHistory";
import { useAppDispatch } from "../../../store";
import { selectTgUser } from "../../../store/selectors/user/tg";
import useUserId from "../../../hooks/userUserId";
import { setTgUser } from "../../../store/reducers/user/ts";
import { setUserBalanceLoading } from "../../../store/reducers/user/balance";
import { setIsVisibleInfoModal } from "../../../store/reducers/modals";

import { InfoModal } from "../../InfoModal";

import info from "../../../icons/onboarding-info.svg";
import tetherIcon from "../../../icons/tether.svg";

import "./Terminal.scss";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { fetchUserOpenAssets } from "../../../store/actionCreators/user/assets";

interface TerminalProps {
  currentToken: any;
  currentPrice: string;
}

type TLimit = {
  min: string;
  max: string;
};

const initialInputValue = "1";
const breakpoints = [0, 25, 50, 75, 99];

export const Terminal = ({ currentPrice, currentToken }: TerminalProps) => {
  const dispatch = useAppDispatch();

  const tgUser = useSelector(selectTgUser);
  const userId = useUserId();
  // const [initDataUnsafe] = useInitData();
  const { initDataRaw } = retrieveLaunchParams();

  const [buyCountValue, setBuyCountValue] = useState(initialInputValue);
  const [totalValue, setTotalValue] = useState<any>(0);
  const [maxInputValue, setMaxInputValue] = useState(
    tgUser?.usdt / Number(currentPrice)
  );
  const [accordionCheckbox, setAccordionCheckbox] = useState<boolean>(false);
  const [isHasOpenedPosition, setIsHasOpenedPosition] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [percentage, setPercentage] = useState(0);

  const updatePositions = useUpdatePositions(initDataRaw, "open");
  const updateHistory = useUpdateHistory(initDataRaw);

  const [limit, setLimit] = useReducer<Reducer<TLimit, Partial<TLimit>>>(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { max: currentToken.take_profit, min: currentToken.stop_loss }
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const maxTokens = tgUser?.usdt / Number(currentPrice);

    setMaxInputValue(maxTokens);

    setIsHasOpenedPosition(currentToken.type);
  }, [currentPrice, tgUser, currentToken]);

  useEffect(() => {
    setTotalValue(getTotalPrice(buyCountValue, currentPrice).toFixed(6));
  }, [currentPrice, buyCountValue, totalValue]);

  useEffect(() => {
    const target = inputRef.current;
    const min = Number(target.min);
    const max = Number(target.max);
    const val = Number(target.value);
    const percentage = ((val - min) * 100) / (max - min);
    if (percentage <= 100) {
      setPercentage(percentage);
    }

    target.style.backgroundSize = percentage + "% 100%";
  }, [buyCountValue, totalValue, maxInputValue]);

  useEffect(() => {
    if (Number(totalValue) <= 1 && currentPrice !== "0") {
      const l = 1 / Number(currentPrice);
      if (l <= 1) {
        setBuyCountValue(l.toFixed(6).toString());
      } else {
        setBuyCountValue(getBuyCountValue("1", currentPrice).toString());
      }
      setTotalValue("1");
    }
  }, []);

  useEffect(() => {
    if (isHasOpenedPosition) {
      setBuyCountValue(currentToken.amount.toString());
      setTotalValue(
        getTotalPrice(currentToken.amount.toString(), currentPrice).toString()
      );
    }
  }, [isHasOpenedPosition, currentToken, currentPrice]);

  const buy = () => {
    if (
      (Number(limit.max) < Number(currentPrice) ||
        Number(limit.min) > Number(currentPrice)) &&
      (Number(limit.max) !== 0 || Number(limit.min) !== 0)
    ) {
      toast.error("wrong limit, please change it");
      return;
    }
    dispatch(setUserBalanceLoading(true));
    setIsDisabledButton(true);

    userAPI
      .buyToken({
        userId,
        currentPrice,
        buyCountValue,
        symbol: currentToken.symbol,
        stopLoss: limit.min,
        takeProfit: limit.max,
        initDataRaw,
      })
      .then(async (res) => {
        const { data } = await userAPI.getTgUser(userId, initDataRaw);

        dispatch(setTgUser(data.user));

        updatePositions();
        updateHistory();
        dispatch(fetchUserOpenAssets(userId, initDataRaw, "open"));
        toast.success(
          `You have successfully bought ${buyCountValue} ${currentToken.symbol} for ${totalValue} USDT`
        );
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setIsDisabledButton(false);
        }, 2000);
        setTimeout(() => {
          dispatch(setUserBalanceLoading(false));
        }, 10000);
      });
  };

  const sell = () => {
    if (
      (Number(limit.max) > Number(currentPrice) ||
        Number(limit.min) < Number(currentPrice)) &&
      (Number(limit.max) !== 0 || Number(limit.min) !== 0)
    ) {
      toast.error("wrong limit, please change it");
      return;
    }
    dispatch(setUserBalanceLoading(true));
    setIsDisabledButton(true);

    userAPI
      .sellToken({
        userId,
        currentPrice,
        sellCountValue: buyCountValue,
        symbol: currentToken.symbol,
        stopLoss: limit?.min,
        takeProfit: limit?.max,
        initDataRaw,
      })
      .then(async (res) => {
        const { data } = await userAPI.getTgUser(userId, initDataRaw);

        dispatch(setTgUser(data.user));
        updatePositions();
        updateHistory();
        dispatch(fetchUserOpenAssets(userId, initDataRaw, "open"));

        toast.success(
          `You have successfully sold ${buyCountValue} ${currentToken.symbol} for ${totalValue} USDT`
        );
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setTimeout(() => {
          setIsDisabledButton(false);
        }, 2000);
        setTimeout(() => {
          dispatch(setUserBalanceLoading(false));
        }, 10000);
      });
  };

  const handleInput = (value: any) => {
    if (isHasOpenedPosition) return;
    setBuyCountValue(value);
    setTotalValue(getTotalPrice(value, currentPrice).toString());
  };

  const onAllClick = () => {
    handleInput(maxInputValue - 1);
  };

  useEffect(() => {
    if (Number(buyCountValue) < 0) {
      setBuyCountValue("0");
    }
    if (maxInputValue < Number(buyCountValue)) {
      setBuyCountValue(String(maxInputValue));
    }
  }, [buyCountValue, maxInputValue]);

  const onPlusClick = () => {
    const percent = (Number(maxInputValue) * 10) / 100;
    setBuyCountValue("0");
    handleInput(Number(buyCountValue) + percent);
  };
  const onMinusClick = () => {
    const percent = (Number(maxInputValue) * 10) / 100;
    setBuyCountValue("0");

    handleInput(Number(buyCountValue) - percent);
  };

  const [selectedTab, setSelectedTab] = useState<"long" | "short">("long");

  // const onBtnClick = () => {
  //   if (selectedTab === "long") {
  //     buy();
  //   } else {
  //     sell();
  //   }
  // };

  // const onInfoClick = () => {
  //   dispatch(setIsVisibleInfoModal(true));
  // };

  useEffect(() => {
    if (Number(buyCountValue) < 0) {
      setBuyCountValue("1");
    }
  }, []);

  return (
    <div className={"widget__terminal terminal"}>
      <div className="terminal-wrap">
        <div className={"terminal__inputs"}>
          <div className="terminal__wrapper_option_up">
            <div className="terminal__wrapper_option_selection">
              <div className="terminal__wrapper_option_center">
                <div className="terminal__wrapper_option_center_input">
                  <img src={tetherIcon} alt="" />
                  {Number(totalValue).toFixed(0)}
                </div>
              </div>
              <div className="terminal__wrapper_options">
                <button
                  className="terminal__wrapper_option_button"
                  onClick={onMinusClick}
                >
                  -
                </button>
                <button
                  className="terminal__wrapper_option_button"
                  onClick={onPlusClick}
                >
                  +
                </button>
              </div>
            </div>
            <button
              className="terminal__wrapper_option_button-all"
              onClick={onAllClick}
            >
              ALL
            </button>
          </div>

          <div className={"terminal__recieve"}>
            <p>
              You will recieve {currentToken.symbol.toUpperCase()}:{" "}
              <span>{buyCountValue}</span>
            </p>
          </div>
          <div className={"terminal__recieve"}>
            <p>Fee {tgUser?.trade_lvl.commission}%</p>
          </div>
          <div className="terminal__range">
            {/* <div
              className="terminal__range-tooltip"
              style={{ left: `${percentage}%` }}
            >
              <span>{Number(buyCountValue).toFixed(0)}%</span>
            </div> */}
            <div className="terminal__range-breakpoints">
              {breakpoints.map((item) => (
                <div
                  className={classNames(
                    "terminal__range-breakpoint",
                    `terminal__range-breakpoint_${item}`,
                    percentage >= 99 &&
                      item === 99 &&
                      `terminal__range-breakpoint_99_left`,
                    percentage >= 74 &&
                      item === 75 &&
                      `terminal__range-breakpoint_75_left`
                  )}
                  style={
                    percentage >= item - 1 ? { backgroundColor: "#D9D9D9" } : {}
                  }
                ></div>
              ))}
            </div>

            <input
              ref={inputRef}
              type="range"
              min="0.001"
              max={maxInputValue}
              value={Number(buyCountValue)}
              onChange={(e) => handleInput(e.target.value)}
              step="0.001"
            />
          </div>
        </div>
      </div>
      <div className="accordion">
        <div className="accordion__header">
          <p>Take Profit / Stop Loss</p>
          <input
            type="checkbox"
            className="accordion__check"
            onClick={() => setAccordionCheckbox(!accordionCheckbox)}
          />
        </div>
        <div
          className={`accordion__body accordion__body${
            accordionCheckbox ? "_open" : "_close"
          }`}
        >
          <div className={"terminal__label"}>
            <div className={"terminal__placeholder text_13"}>Take Profit</div>
            <input
              type="text"
              className={"terminal__input text_16 "}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value)) || isHasOpenedPosition)
                  return;
                setLimit({ max: e.target.value });
              }}
              value={limit.max}
              placeholder="0"
            />
          </div>
          <div className={"terminal__label"}>
            <div className={"terminal__placeholder text_13"}>Stop Loss</div>
            <input
              type="text"
              className={"terminal__input text_16 "}
              placeholder="0"
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value)) || isHasOpenedPosition)
                  return;
                setLimit({ min: e.target.value });
              }}
              value={limit.min}
            />
          </div>
        </div>
      </div>
      <button
        className="terminal__btn buy"
        onClick={buy}
        disabled={isDisabledButton}
      >
        Buy/ Long
      </button>
      <button
        className="terminal__btn sell"
        onClick={sell}
        disabled={isDisabledButton}
      >
        Sell / Short
      </button>
      <InfoModal />
    </div>
  );
};
