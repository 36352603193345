// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  background: linear-gradient(180deg, #8c5af8 21%, #e7e7e7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.preloader__title {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Preloader/Preloader.scss"],"names":[],"mappings":"AAAA;EACE,8DAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".preloader {\n  background: linear-gradient(180deg, #8c5af8 21%, #e7e7e7 100%);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100vh;\n  &__title {\n    color: #ffffff;\n    font-size: 36px;\n    font-weight: 600;\n    margin-top: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
