import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

import { animationBottom, animationCenter } from "../../utils/animation";
import { assetsAPI } from "../../api/assets";
import useUserId from "../../hooks/userUserId";

import { RatingTable } from "../../components/RatingTable";

import arrowUp from "../../icons/earn-arrow.svg";
import arrowDown from "../../icons/percent-arrow-down.svg";

import "./Leaderboard.scss";
import classNames from "classnames";
import { ProfileCard } from "../../components/ProfileCard";
import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";

const Leaderboard = () => {
  const userId = useUserId();
  const pageRef = useRef<any>();
  const { initDataRaw } = retrieveLaunchParams();
  const tgUser = useSelector(selectTgUser);

  const [user, setUser] = useState(null);

  useEffect(() => {
    (async function () {
      const user = await assetsAPI.getUserPosition(
        userId,
        tgUser?.trade_lvl.lvl,
        initDataRaw
      );

      console.log(user);

      setUser(user.data);
    })();
  }, [setUser]);

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <motion.div
      ref={pageRef}
      className="leaderboard"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      variants={animationCenter}
    >
      <div className="bg"></div>
      <ProfileCard />
      <div className="live-rating">
        <RatingTable />
      </div>
      <div className="leaderboard__me active">
        <motion.div
          variants={animationBottom}
          custom={2}
          className="rating__item"
        >
          <div className="rating__place">{user?.position}</div>
          <p className="rating__name">Me</p>
          <p className="rating__balance">{user?.balance.toFixed(2)}</p>
          <p
            className={classNames(
              "rating__profit",
              user?.total_pnl < 0 ? "rating__profit_red" : ""
            )}
          >
            {user?.total_pnl >= 0 ? (
              <img src={arrowUp} alt="" />
            ) : (
              <img src={arrowDown} alt="" />
            )}
            {user?.total_pnl > 0
              ? `+${user?.total_pnl}%`
              : `${user?.total_pnl}%`}
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Leaderboard;
