import React, { useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { selectTgUser } from "../../store/selectors/user/tg";

import avatarMock from "../../icons/avatar-mock.svg";
import lockIcon from "../../icons/profile-lock.svg";
import checkedImg from "../../icons/profile-checked.svg";
import walletBalanceIcon from "../../icons/wallet-balance.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Profile.scss";
import {
  selectCurrentValue,
  selectPercentChange,
  selectProfitChange,
} from "../../store/selectors/user/balance";
import classNames from "classnames";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  animationBottom,
  animationCenter,
  animationLeft,
  animationRight,
  animationTop,
} from "../../utils/animation";

const levels = [
  {
    index: 0,
    name: "Begginer",
    lvl: 1,
    requirements: [
      {
        title: "Trading volume",
        amount: "100k",
        met: false,
      },
    ],
    fee: 1,
    pairs: "BTC, ETH, TON",
  },
  {
    index: 1,
    name: "Researcher",
    lvl: 2,
    requirements: [
      {
        title: "Balance",
        amount: "250k",
      },
      {
        title: "Friends",
        amount: 1,
      },
    ],
    fee: 0.9,
    pairs: "SOL, XRP",
  },
  {
    index: 2,
    name: "Researcher",
    lvl: 3,
    requirements: [
      {
        title: "Volume",
        amount: "1M",
      },
      {
        title: "Friends",
        amount: 3,
      },
    ],
    fee: 0.8,
    pairs: "PEPE, WIF",
  },
  {
    index: 3,
    name: "Researcher",
    lvl: 4,
    requirements: [
      {
        title: "Volume",
        amount: "5M",
      },
      {
        title: "Friends",
        amount: 3,
      },
      {
        title: "Balance",
        amount: "2.5M",
      },
    ],
    fee: 0.7,
    pairs: "NEAR, APT",
  },
  {
    index: 4,
    name: "Researcher",
    lvl: 5,
    requirements: [
      {
        title: "Volume",
        amount: "10M",
      },
      {
        title: "Balance",
        amount: "5M",
      },
    ],
    fee: 0.6,
    pairs: "LINCH, MATIC, ARB",
  },
  {
    index: 5,
    name: "Researcher",
    lvl: 6,
    requirements: [
      {
        title: "Volume",
        amount: "25M",
      },
      {
        title: "Friends",
        amount: 5,
      },
    ],
    fee: 0.5,
    pairs: "DOGE, LTC",
  },
  {
    index: 6,
    name: "Researcher",
    lvl: 7,
    requirements: [
      {
        title: "Balance",
        amount: "250M",
      },
      {
        title: "Friends",
        amount: 5,
      },
    ],
    fee: 0.4,
    pairs: "ATOM, DOT, PEOPLE",
  },
  {
    index: 7,
    name: "Researcher",
    lvl: 8,
    requirements: [
      {
        title: "Volume",
        amount: "100M",
      },
      {
        title: "Friends",
        amount: 7,
      },
    ],
    fee: 0.3,
    pairs: "TRX, AVAX",
  },
  {
    index: 8,
    name: "Researcher",
    lvl: 9,
    requirements: [
      {
        title: "Volume",
        amount: "250M",
      },
      {
        title: "Balance",
        amount: "125M",
      },
      {
        title: "Friends",
        amount: 10,
      },
    ],
    fee: 0.2,
    pairs: "OP, BNB, LINK",
  },
  {
    index: 9,
    name: "Researcher",
    lvl: 10,
    requirements: [],
    fee: 0.1,
    pairs: "SHIB, FLOKI",
  },
];

export const Profile = () => {
  const tgUser = useSelector(selectTgUser);
  const currentValue = useSelector(selectCurrentValue);
  // const percentChange = useSelector(selectPercentChange);
  // const profitChange = useSelector(selectProfitChange);
  const [selectedLevel, setSelectedLevel] = useState(levels[0]);

  const onNextLvlClick = () => {
    if (selectedLevel.lvl === 10) {
      return;
    }
    setSelectedLevel(levels[selectedLevel?.index + 1]);
  };

  const onPrevLvlClick = () => {
    if (selectedLevel.lvl === 1) {
      return;
    }
    setSelectedLevel(levels[selectedLevel?.index - 1]);
  };

  return (
    <motion.div
      className="profile"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      variants={animationCenter}
    >
      <div className="bg"></div>
      <motion.div
        className="profile__header"
        variants={animationTop}
        custom={2}
      >
        <img
          src={avatarMock}
          alt="аватарка"
          className="profile-card__header-avatar"
        />
        <span>{tgUser?.username ? tgUser?.username : "John"}</span>
      </motion.div>
      <motion.div
        className="profile__info widget-card"
        variants={animationTop}
        custom={2.2}
      >
        <div className="profile__info-header">
          <h4>Lvl {selectedLevel.lvl}/10</h4>
          <div className="profile__info-header-arrows">
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classNames(
                "arrow-left",
                selectedLevel.lvl === 1 ? "hidden" : ""
              )}
              onClick={onPrevLvlClick}
            >
              <path
                d="M5.6129 0.237466L0.17742 5.55673C0.112904 5.62005 0.0673125 5.68865 0.0406459 5.76253C0.0135491 5.83641 2.65959e-07 5.91557 2.62268e-07 6C2.58578e-07 6.08443 0.0135491 6.16359 0.0406459 6.23747C0.0673125 6.31134 0.112904 6.37995 0.17742 6.44327L5.6129 11.7784C5.76344 11.9261 5.95161 12 6.17742 12C6.40323 12 6.59677 11.9208 6.75806 11.7625C6.91936 11.6042 7 11.4195 7 11.2084C7 10.9974 6.91936 10.8127 6.75806 10.6544L2.01613 6L6.75807 1.34565C6.9086 1.19789 6.98387 1.01594 6.98387 0.799788C6.98387 0.583219 6.90323 0.395777 6.74194 0.237466C6.58065 0.0791549 6.39247 -2.65558e-08 6.17742 -3.59561e-08C5.96237 -4.53564e-08 5.77419 0.0791549 5.6129 0.237466Z"
                fill="black"
              />
            </svg>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onNextLvlClick}
              className={classNames(
                "arrow-right",
                selectedLevel.lvl === 10 ? "hidden" : ""
              )}
            >
              <path
                d="M1.3871 0.237466L6.82258 5.55673C6.8871 5.62005 6.93269 5.68865 6.95935 5.76253C6.98645 5.83641 7 5.91557 7 6C7 6.08443 6.98645 6.16359 6.95935 6.23747C6.93269 6.31134 6.8871 6.37995 6.82258 6.44327L1.3871 11.7784C1.23656 11.9261 1.04839 12 0.82258 12C0.596774 12 0.403226 11.9208 0.241935 11.7625C0.0806449 11.6042 -1.61467e-08 11.4195 -3.46e-08 11.2084C-5.30534e-08 10.9974 0.0806448 10.8127 0.241935 10.6544L4.98387 6L0.241934 1.34565C0.0913967 1.19789 0.0161276 1.01594 0.0161276 0.799788C0.0161276 0.583219 0.0967725 0.395777 0.258063 0.237466C0.419353 0.0791549 0.607526 -2.65558e-08 0.822579 -3.59561e-08C1.03763 -4.53564e-08 1.22581 0.0791549 1.3871 0.237466Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <div className="profile__info-progress">
          <div className="profile__info-progress-value"></div>
        </div>
        <div className="profile__info-name">
          <h4>{selectedLevel.name}</h4>
          <img src={lockIcon} alt="" />
        </div>
        <div className="profile__info-requirements">
          {selectedLevel.requirements.map((item) => (
            <div
              className={classNames(
                "profile__info-block",
                selectedLevel.requirements.length > 1 ? "margin" : ""
              )}
            >
              <p className="profile__info-block-title">{item.title}:</p>
              <p className="profile__info-block-count">{item.amount}</p>
            </div>
          ))}
        </div>

        <div className="profile__info-commissions">
          <div className="profile__info-commissions-block">
            <img src={checkedImg} alt="" />
            <p>Comission fee: {selectedLevel.fee}%</p>
          </div>
          <div className="profile__info-commissions-block">
            <img src={checkedImg} alt="" />
            <p>Pairs: {selectedLevel.pairs}</p>
          </div>{" "}
          <div className="profile__info-commissions-block">
            <img src={checkedImg} alt="" />
            <p>New strategy</p>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="profile__wallet widget-card"
        variants={animationBottom}
        custom={2}
      >
        <div className="profile__wallet-wrap">
          <div>
            <div className="profile__wallet-title">Wallet</div>
            <div className="profile__wallet-value">
              <img src={walletBalanceIcon} alt="" />
              <div style={{ marginLeft: 8 }}>
                <p>
                  {currentValue
                    ? Number(currentValue).toFixed(2)
                    : "1,096,252.00"}
                </p>
                <span>Balance</span>
              </div>
            </div>
            <div className="profile__wallet-value">
              <img src={walletBalanceIcon} alt="" />
              <div style={{ marginLeft: 8 }}>
                <p>8,252.25</p>
                <span>Total transactions</span>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {tgUser?.net_profit_loss !== 0 ? (
              <div className="profile__wallet-change">
                <div
                  className={classNames("profile__wallet-usdt", {
                    "profile__wallet-usdt_red": tgUser?.net_profit_loss < 0,
                  })}
                >
                  {tgUser?.net_profit_loss > 0
                    ? `+${Math.abs(tgUser?.net_profit_loss.toFixed(2))}$`
                    : tgUser?.net_profit_loss < 0
                    ? `-${Math.abs(tgUser?.net_profit_loss.toFixed(2))}$`
                    : "67"}
                </div>
                <div
                  className={classNames("profile__wallet-percent", {
                    "profile__wallet-percent_red": tgUser?.total_pnl < 0,
                  })}
                >
                  {tgUser?.total_pnl > 0
                    ? `+${Math.abs(tgUser?.total_pnl).toFixed(2)}`
                    : tgUser?.total_pnl < 0
                    ? `-${Math.abs(tgUser?.total_pnl).toFixed(2)}`
                    : "0.01"}
                  %
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Accordion className="profile__wallet-accordion">
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <h2>Statistics</h2>
          </AccordionSummary>
          <AccordionDetails>
            <p>Number of transactions: 23</p>
            <p>Trading volume: 234,453</p>
            <p>Average fee: 4%</p>
          </AccordionDetails>
        </Accordion>
      </motion.div>
    </motion.div>
  );
};
