import React from "react";
import { format } from "date-fns";

import btcIcon from "../../../public/cryptoicons/BTC.png";

import percentUp from "../../icons/percent-up.svg";
import percentDown from "../../icons/percent-down.svg";
import chartUp from "../../icons/chart-up.png";
import chartDown from "../../icons/chart-down.png";
import checkedIcon from "../../icons/checked.svg";

import "./HistoryItem.scss";
import { Skeleton } from "@mui/material";
import classNames from "classnames";

interface Props {
  id?: number;
  asset_tag?: string;
  price?: number;
  amount?: number;
  stop_loss?: string;
  take_profit?: string;
  date?: string;
  profitPercent?: number;
  type?: string;
  price_diff: number;
  pnl: number;
}

const openLongType = "open long position";
const openShortType = "open short position";
const closeLongType = "close long position";
const closeShortType = "close short position";

export const HistoryItem = ({
  asset_tag,
  price,
  amount,
  take_profit,
  date,
  profitPercent,
  stop_loss,
  type,
  price_diff,
  pnl,
}: Props) => {
  console.log(type);

  const formatedDate = format(date, "yyyy-MM-dd hh:mm");

  return (
    <div className="history-item">
      <div className="history-item__row">
        <div
          className="history-item__block"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="history-item__info-coin">
            <img
              className="history-item__info-coin-icon"
              src={`cryptoicons/${asset_tag.toUpperCase()}.png`}
              alt="коин"
            />
            <div className="history-item__info-coin-symbol">
              {asset_tag.toUpperCase()}
            </div>
          </div>
          <div
            className={classNames(
              "history-item__type",
              type === openShortType ||
                type === closeLongType ||
                type === closeShortType
                ? "history-item__type_red"
                : ""
            )}
          >
            {type}
          </div>
        </div>
        <div className="history-item__block">
          <div className="history-item__info-price">
            <p>
              <span>{amount.toFixed(3)}</span> BTC |{" "}
              <span>{price.toFixed(3)}</span> USDT
            </p>
          </div>
          {type === closeLongType || type === closeShortType ? (
            <div
              className={classNames(
                "history-item__info-percent",
                pnl < 0 ? "history-item__info-percent_red" : ""
              )}
            >
              <p>
                {price_diff > 0
                  ? `+${price_diff.toFixed(2)}`
                  : price_diff.toFixed(2)}{" "}
                <span>USDT</span> (
                {pnl > 0 ? `+${pnl.toFixed(2)}` : pnl.toFixed(2)}%)
              </p>
            </div>
          ) : null}
        </div>
        <div className="history-item__block">
          {take_profit || stop_loss ? (
            <div className="history-item__tp">
              TP/SL <img src={checkedIcon} alt="" />
            </div>
          ) : null}
          {take_profit || stop_loss ? (
            <div>
              <p>
                {take_profit ? take_profit : "0"} -{" "}
                {stop_loss ? stop_loss : "0"} USDT
              </p>
            </div>
          ) : null}
        </div>
        <div className="history-item__block">
          <div>
            <p>Comission fee</p>
          </div>
          <div>
            <p>10 USDT</p>
          </div>
        </div>{" "}
        <div className="history-item__block">
          <div>
            <p>Date of issue </p>
          </div>
          <div>
            <p>{formatedDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
