import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetFileds } from "../../../types/user/assets";

interface userAssetsState {
  userOpenAssets: AssetFileds[];
  userClosedAssets: AssetFileds[];
}

const initialState: userAssetsState = {
  userOpenAssets: [],
  userClosedAssets: [],
};

export const userAssetsSlice = createSlice({
  name: "userAssets",
  initialState,
  reducers: {
    setUserOpenAssets(state, action: PayloadAction<AssetFileds[]>) {
      state.userOpenAssets = action.payload;
    },
    setUserClosedAssets(state, action: PayloadAction<AssetFileds[]>) {
      state.userClosedAssets = action.payload;
    },
  },
});

export const { setUserOpenAssets, setUserClosedAssets } =
  userAssetsSlice.actions;
export const userAssetsReducer = userAssetsSlice.reducer;
