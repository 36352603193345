// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard {
  min-height: 100vh;
  padding-bottom: 30px;
}
.leaderboard__me {
  padding: 15px;
  margin-top: 70px;
  border-top: 1px solid #e0dfdf;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1019607843);
  background-color: #ffffff;
  border-radius: 15px;
}

.live-rating {
  max-width: 1162px;
  color: #fff;
}

.leaderboard-bottom {
  width: 1162px;
  height: 32px;
  font-size: 20px;
  color: #959595;
  margin: 0 auto 20px auto;
}

@media screen and (max-width: 991px) {
  .leaderboard {
    padding-bottom: 80px;
    flex-wrap: wrap;
    height: auto;
    background-position: bottom center;
    justify-content: center;
  }
  .trading-challenge {
    flex-basis: 100%;
    width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Leaderboard/Leaderboard.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,oBAAA;AADF;AAEE;EACE,aAAA;EACA,gBAAA;EACA,6BAAA;EACA,uDAAA;EACA,yBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,WAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,wBAAA;AADF;;AAGA;EACE;IACE,oBAAA;IACA,eAAA;IACA,YAAA;IACA,kCAAA;IACA,uBAAA;EAAF;EAEA;IACE,gBAAA;IACA,WAAA;EAAF;AACF","sourcesContent":["@import \"../../variables.scss\";\n\n.leaderboard {\n  min-height: 100vh;\n  padding-bottom: 30px;\n  &__me {\n    padding: 15px;\n    margin-top: 70px;\n    border-top: 1px solid #e0dfdf;\n    box-shadow: 0px 4px 8px 0px #0000001a;\n    background-color: #ffffff;\n    border-radius: 15px;\n  }\n}\n\n.live-rating {\n  max-width: 1162px;\n  color: #fff;\n}\n\n.leaderboard-bottom {\n  width: 1162px;\n  height: 32px;\n  font-size: 20px;\n  color: #959595;\n  margin: 0 auto 20px auto;\n}\n@media screen and (max-width: $tablet-xl) {\n  .leaderboard {\n    padding-bottom: 80px;\n    flex-wrap: wrap;\n    height: auto;\n    background-position: bottom center;\n    justify-content: center;\n  }\n  .trading-challenge {\n    flex-basis: 100%;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
