import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { AssetItem } from "../../types/user/assets";
import { assetsAPI } from "../../api/assets";
import { useAppDispatch } from "../../store";
import { selectAssets } from "../../store/selectors/assets";
import { setAssets } from "../../store/reducers/assets";
import { animationBottom } from "../../utils/animation";

import { AssetRow } from "../AssetRow";

import "./AssetList.scss";
import useUserId from "../../hooks/userUserId";

export interface Price {
  symbol: string;
  price: string;
}

export interface AssetListProps {
  searchValue?: string;
}

const rowsPerPage = 10;

export const AssetList = ({ searchValue }: AssetListProps) => {
  const dispatch = useAppDispatch();

  const assets = useSelector(selectAssets);
  const userId = useUserId();

  useEffect(() => {
    (async () => {
      const { data } = await assetsAPI.getAllAssets(userId);

      const filteredAssets = data.filter(
        (item) =>
          item.symbol.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );

      dispatch(setAssets(filteredAssets));
    })();
  }, [searchValue]);

  return (
    <div className={"asset-list"}>
      <div className="asset-list__wrap">
        {assets.length ? (
          assets.map((token: AssetItem, index: number) => {
            if (token.symbol === "cro") return null;
            return (
              <AnimatePresence key={token.symbol}>
                <AssetRow currentToken={token} delay={index} />;
              </AnimatePresence>
            );
          })
        ) : (
          <motion.div
            custom={1.4}
            variants={animationBottom}
            className="asset-list__empty"
          >
            <p className="asset-list__empty-title">No assets</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};
