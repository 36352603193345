import axios from "axios";

const user = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API + "users",
});

type TBuyTokenParams = {
  userId: number;
  currentPrice: string;
  buyCountValue: string;
  symbol: string;
  takeProfit: string;
  stopLoss: string;
  initDataRaw: any;
};

type TSellShortParams = {
  userId: number;
  currentPrice: string;
  sellCountValue: string;
  symbol: string;
  takeProfit: string;
  stopLoss: string;
  initDataRaw: any;
};

type TCheckParams = {
  userId: number;
  currency: string;
};

export const userAPI = {
  getFriends: (userId: number, initDataRaw: any) => {
    return user.get(`/user_friends/${userId}`, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getTgUser: (userId: number, initDataRaw: any) => {
    return user.get(`/${userId}`, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  buyToken: ({
    userId,
    symbol,
    currentPrice,
    buyCountValue,
    stopLoss,
    takeProfit,
    initDataRaw,
  }: TBuyTokenParams) => {
    return user.post(`${userId}/long`, null, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        price: currentPrice,
        amount: buyCountValue,
        currency: symbol.toLowerCase(),
        take_profit: takeProfit,
        stop_loss: stopLoss,
      },
    });
  },
  sellToken: ({
    userId,
    currentPrice,
    symbol,
    sellCountValue,
    stopLoss,
    takeProfit,
    initDataRaw,
  }: TSellShortParams) => {
    return user.post(`${userId}/short`, null, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        price: currentPrice,
        amount: sellCountValue,
        currency: symbol.toLowerCase(),
        take_profit: takeProfit,
        stop_loss: stopLoss,
      },
    });
  },
  closePosition: (
    userId: number,
    symbol: string,
    positionId: string,
    initDataRaw: any,
    tokenPrice: number
  ) => {
    return user.post(`${userId}/close`, [positionId], {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {
        currency: symbol.toLowerCase(),
        price: tokenPrice,
      },
    });
  },
  check: ({ userId, currency }: TCheckParams) => {
    return user.post(`${userId}/check`, null, {
      params: {
        currency: currency.toLowerCase(),
      },
    });
  },
  getTotalAssetsPrice: (
    address: string,
    btcPrice: string,
    ethPrice: string,
    bnbPrice: string
  ) => {
    return user.get("/amount", {
      params: {
        eth_address: address,
        price_btc: btcPrice,
        price_eth: ethPrice,
        price_bnb: bnbPrice,
      },
    });
  },
  getBalance: (
    address: string,
    btcPrice: string,
    ethPrice: string,
    bnbPrice: string
  ) => {
    return user.get("/balance", {
      params: {
        eth_address: address,
        price_btc: btcPrice,
        price_eth: ethPrice,
        price_bnb: bnbPrice,
      },
    });
  },
};
