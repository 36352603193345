// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0 20px 0;
  padding: 10px;
}
@media screen and (max-height: 668px) {
  .onboarding__header {
    margin: 10px 0;
  }
}
.onboarding__header svg {
  z-index: 999;
}
.onboarding__header-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}
.onboarding__header-tab-bg {
  position: absolute;
  left: 6px;
  top: 0;
}
.onboarding__header-skip {
  display: flex;
  align-items: center;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Header/Header.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,qBAAA;EACA,aAAA;AAAJ;AACI;EAPF;IAQI,cAAA;EAEJ;AACF;AADI;EACE,YAAA;AAGN;AADI;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;AAGN;AAFM;EACE,kBAAA;EACA,SAAA;EACA,MAAA;AAIR;AADI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAGN","sourcesContent":[".onboarding {\n  &__header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    margin: 15px 0 20px 0;\n    padding: 10px;\n    @media screen and (max-height: 668px) {\n      margin: 10px 0;\n    }\n    svg {\n      z-index: 999;\n    }\n    &-tab {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      position: relative;\n      color: #fff;\n      &-bg {\n        position: absolute;\n        left: 6px;\n        top: 0;\n      }\n    }\n    &-skip {\n      display: flex;\n      align-items: center;\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
