import React from "react";
import classNames from "classnames";

import history from "../../icons/balance-history.svg";
import walletBalanceIcon from "../../icons/wallet-balance.svg";

import "./BalanceProfitCard.scss";
import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";
import {
  selectCurrentValue,
  selectPercentChange,
  selectProfitChange,
} from "../../store/selectors/user/balance";

interface Props {
  // profitChange?: any;
  // currentValue?: number;
  // percentChange?: number;
  style?: any;
  className?: string;
}

export const BalanceProfitCard = ({
  // profitChange,
  // currentValue,
  // percentChange,
  className,
}: Props) => {
  const tgUser = useSelector(selectTgUser);
  const currentValue = useSelector(selectCurrentValue);
  // const profitChange = useSelector(selectProfitChange);
  // const percentChange = useSelector(selectPercentChange);

  return (
    <div
      className={classNames("balance-profit box-shadow widget-card", className)}
    >
      <div>
        <div className="balance-profit__title">Wallet</div>
        <div className="balance-profit__value">
          <img src={walletBalanceIcon} alt="" />
          <div style={{ marginLeft: 8 }}>
            <p>
              {currentValue ? Number(currentValue).toFixed(2) : "1,096,252.00"}
              {` `}
              <span>USDT</span>
            </p>
            <span>Total</span>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        {tgUser?.net_profit_loss !== 0 ? (
          <div className="balance-profit__change">
            <div
              className={classNames("balance-profit__usdt", {
                "balance-profit__usdt_red": tgUser?.net_profit_loss < 0,
              })}
            >
              {tgUser?.net_profit_loss > 0
                ? `+${Math.abs(tgUser?.net_profit_loss.toFixed(2))}$`
                : tgUser?.net_profit_loss < 0
                ? `-${Math.abs(tgUser?.net_profit_loss.toFixed(2))}$`
                : "67"}
            </div>
            <div
              className={classNames("balance-profit__percent", {
                "balance-profit__percent_red": tgUser?.total_pnl < 0,
              })}
            >
              {tgUser?.total_pnl > 0
                ? `+${Math.abs(tgUser?.total_pnl).toFixed(2)}`
                : tgUser?.total_pnl < 0
                ? `-${Math.abs(tgUser?.total_pnl).toFixed(2)}`
                : "0.01"}
              %
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
