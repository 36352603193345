import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import arrowUp from "../../icons/percent-up.svg";
import refTg from "../../icons/ref-tf.svg";
import refresh from "../../icons/refresh.svg";
import close from "../../icons/close-icon.png";
import copy from "../../icons/ref_copy.svg";
import copied from "../../icons/ref_copied.svg";
import { Drawer } from "@mui/material";
import "./Refferals.scss";
import { motion } from "framer-motion";
import {
  animationLeft,
  animationRight,
  animationTop,
} from "../../utils/animation";
import { userAPI } from "../../api/user";
import useUserId from "../../hooks/userUserId";
import { useSelector } from "react-redux";
import { selectUserFriends } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { setFriends } from "../../store/reducers/user";
import { postEvent, retrieveLaunchParams } from "@telegram-apps/sdk";

export const Refferals = () => {
  const userId = useUserId();
  const dispatch = useAppDispatch();
  const pageRef = useRef<any>();
  const { initDataRaw } = retrieveLaunchParams();

  const friends = useSelector(selectUserFriends);

  const [open, setOpen] = useState(false);
  const [copiedLinks, setCopiedLinks] = useState("");
  const [viewCoppied, setViewCoppied] = useState(false);

  const link = "https://t.me/demotradeapp_bot";

  const toggleModal = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setCopiedLinks("");
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };
  function copyClipboard() {
    navigator.clipboard.writeText(link).then(() => {
      setCopiedLinks(link);
      setViewCoppied(true);
    });
    setTimeout(() => setViewCoppied(false), 2000);
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  }

  useEffect(() => {
    (async function () {
      // const { data } = await userAPI;
      const { data } = await userAPI.getFriends(userId, initDataRaw);

      dispatch(setFriends(data.friends));
    })();
  }, []);

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      ref={pageRef}
      className="container"
    >
      <div
        className={
          viewCoppied
            ? "earn__modal_copied"
            : "earn__modal_copied earn__modal_disabled"
        }
      >
        <p>
          <img src={copied} alt="" />
        </p>
        <div className="earn__modal_copied_text">Text copied!</div>
      </div>

      <div className="refferals">
        <motion.div variants={animationTop} className="refferals__title">
          <img src={arrowUp} alt="" />
          <span>Invite friends!</span>
        </motion.div>
        <motion.div
          variants={animationTop}
          custom={1.5}
          className="refferals__subtitle"
        >
          <p>
            +<span>10,000</span> coins for each friend
          </p>
        </motion.div>
        <motion.div
          variants={animationLeft}
          custom={1.4}
          className="refferals__item"
          onClick={toggleModal(true)}
        >
          <img src={refTg} alt="телеграмм" />
          <div className="refferals__item-body">
            <span>Invite friend</span>
            <p>
              <img src={arrowUp} alt="" /> <span> 10,000 </span> USDT for you
              and your friend
            </p>
          </div>
        </motion.div>

        <div className="refferals__invited">
          <motion.div className="refferals__invited-title">
            <span>Already invited</span>
            <img src={refresh} alt="обновить" />
          </motion.div>
          <div className="refferals__invited-friends">
            {friends?.length ? (
              friends.map((item) => (
                <motion.div
                  variants={animationRight}
                  custom={1.2 * item.id}
                  className="refferals__invited-friend"
                  key={item.id}
                >
                  <span> {item.username}</span>
                  <p>
                    <img src={arrowUp} alt="" />{" "}
                    <span>
                      {" "}
                      {item.status === "premium" ? "30,000" : "10,000"} USDT
                    </span>
                  </p>
                </motion.div>
              ))
            ) : (
              <motion.div
                variants={animationRight}
                className="refferals__invited-friend error"
              >
                <p>You haven’t invited any friends yet</p>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <Drawer open={open} anchor="bottom" onClose={toggleModal(false)}>
        <div className="earn__modal">
          <button onClick={toggleModal(false)}>
            <img src={close} alt="" className="earn__modal_close" />
          </button>
          <div className="earn__modal_line"></div>
          <img src={refTg} alt="" className="earn__modal_img" />
          <p className="earn__modal_title">Invite a friend</p>
          <p className="earn__modal_subtitle">
            <p className="earn__item_price">
              <img src={arrowUp} alt="" />
              <span>10,000 </span> USDT for you and your friend
            </p>
          </p>
          <div className="earn__modal__btns">
            <a
              href={`https://t.me/share/url?url=https://t.me/demotradeapp_bot?start=${userId}`}
              target="_blank"
              rel="noreferrer"
              className="earn__modal_btn earn__modal_btn_ref"
            >
              <button>Invite in Telegram</button>
            </a>

            <button
              className="earn__modal_btn earn__modal_btn_copy"
              onClick={copyClipboard}
            >
              <img src={copiedLinks ? copied : copy} alt="" />
            </button>
          </div>
          <div className="earn__modal_bottom"></div>
        </div>
      </Drawer>
    </motion.div>
  );
};
