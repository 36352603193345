import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

import { useAppDispatch } from "../../store";
import { setIsVisibleEarnDailyModal } from "../../store/reducers/modals";
import { selectIsVisibleEarnDailyModal } from "../../store/selectors/modals";
import {
  claimDailyReward,
  fetchDailyRewards,
} from "../../store/actionCreators/earn";
import useUserId from "../../hooks/userUserId";
import { selectDailyRewards } from "../../store/selectors/earn";
import { DailyReward } from "../../types/earn";

import checked from "../../icons/checked.svg";
import close from "../../icons/close-icon.png";

import "./EarnDailyModal.scss";

export const EarnDailyModal = () => {
  const dispatch = useAppDispatch();
  const userId = useUserId();
  const { initDataRaw } = retrieveLaunchParams();

  const isVisibleEarnDailyModal = useSelector(selectIsVisibleEarnDailyModal);
  const dailyRewards = useSelector(selectDailyRewards);
  const [availableDailyReward, setAvailableDailyReward] =
    useState<DailyReward | null>(null);

  const onClaimClick = () => {
    if (!availableDailyReward) {
      return;
    }
    dispatch(claimDailyReward(userId, availableDailyReward.day, initDataRaw));
    // dispatch(fetchDailyRewards(userId));
    dispatch(setIsVisibleEarnDailyModal(false));
  };

  useEffect(() => {
    dispatch(fetchDailyRewards(userId, initDataRaw));
  }, [userId, dispatch, fetchDailyRewards]);

  useEffect(() => {
    const availableDailyReward = dailyRewards.find(
      (item) => item.status === "available"
    );

    setAvailableDailyReward(availableDailyReward);
  }, [dailyRewards, setAvailableDailyReward]);

  return (
    <Drawer
      open={isVisibleEarnDailyModal}
      anchor="bottom"
      onClose={() => dispatch(setIsVisibleEarnDailyModal(false))}
    >
      <div className="earn__modal">
        <button onClick={() => dispatch(setIsVisibleEarnDailyModal(false))}>
          <img src={close} alt="" className="earn__modal_close" />
        </button>
        <div className="earn__modal_line"></div>
        <p className="earn__modal_title">Daily reward</p>
        <p className="earn__modal_subtitle">
          Accure USDT for logging into the game daily without skipping
        </p>
        <div className="earn__modal_wrapper">
          {dailyRewards?.map((item) => (
            <div
              key={item.day}
              className={
                item.status === "available"
                  ? "earn__modal_day earn__modal_day_active"
                  : item.status === "claimed"
                  ? " earn__modal_day earn__modal_day_claimed"
                  : "earn__modal_day"
              }
            >
              <p className="earn__modal_name">Day {item.day}</p>
              <span className="earn__modal_number">
                {item.reward.toLocaleString("en-US", {
                  // add suffixes for thousands, millions, and billions
                  // the maximum number of decimal places to use
                  maximumFractionDigits: 2,
                  // specify the abbreviations to use for the suffixes
                  notation: "compact",
                  compactDisplay: "short",
                })}
              </span>
            </div>
          ))}
        </div>
        <button
          className={
            !availableDailyReward
              ? "earn__modal_btn earn__modal_btn_disabled"
              : "earn__modal_btn"
          }
          onClick={onClaimClick}
        >
          {!availableDailyReward ? (
            <img
              src={checked}
              alt=""
              style={{ marginRight: 5, width: 25, height: 25 }}
            />
          ) : null}
          {!availableDailyReward ? "Come back tomorrow" : "Claim"}
        </button>
        <div className="earn__modal_bottom"></div>
      </div>
    </Drawer>
  );
};
