import React, { useLayoutEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../store";
import { animationBottom, animationTop } from "../../utils/animation";
import { setIsOnboarding } from "../../store/reducers/loading";

import { ReactComponent as Info } from "../../icons/onboarding-info.svg";

import { MainInfo } from "../../components/MainInfo";
import { AssetList } from "../../components/AssetList";
import { Toolbar } from "../../components/Toolbar";
import { ProfileCard } from "../../components/ProfileCard";

import "./Dashboard.scss";

export const Dashboard = () => {
  const pageRef = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>("");

  const onInfoClick = () => {
    navigate("/onboarding/1");
    dispatch(setIsOnboarding(true));
  };

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      ref={pageRef}
    >
      <div className="dashboard">
        <div className="dashboard__bg bg"></div>
        <motion.div custom={2} variants={animationTop}>
          {/* <Header /> */}
          <ProfileCard />
        </motion.div>

        <MainInfo />
        <motion.div
          className="widget-card"
          custom={2.4}
          variants={animationBottom}
        >
          <div className="dashboard__info">
            <span>Tokens</span>
            <Info />
          </div>
          <div className="dashboard__toolbar">
            <Toolbar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          <div className="dashboard__asset-header">
            <div>
              <span>Name</span>
            </div>
            <div>
              <span style={{ marginRight: 30 }}>Last Price</span>
              <span>24h chg%</span>
            </div>
          </div>
          <AssetList searchValue={searchValue} />
        </motion.div>
      </div>
      {/* <ScrollToTop smooth /> */}
    </motion.div>
  );
};
