// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  display: flex;
  align-items: center;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;
  padding: 10px 22px 28px 22px;
  z-index: 999;
}
.menu__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 50px;
  height: 50px;
}
.menu__tab.active {
  background-color: #733de7;
  width: 100%;
  max-width: 125px;
  border-radius: 24px;
}
.menu__tab.active svg {
  margin-right: 5px;
}
.menu__tab.active svg path {
  fill: #fff;
}
.menu__tab svg {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,8BAAA;EACA,sBAAA;EACA,gCAAA;EACA,4BAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AAEJ;AAAI;EACE,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AAEN;AADM;EACE,iBAAA;AAGR;AAFQ;EACE,UAAA;AAIV;AACI;EACE,WAAA;EACA,YAAA;AACN","sourcesContent":[".menu {\n  display: flex;\n  align-items: center;\n  color: #fff;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  justify-content: space-between;\n  background-color: #fff;\n  border-radius: 15px 15px 0px 0px;\n  padding: 10px 22px 28px 22px;\n  z-index: 999;\n  &__tab {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    min-width: 50px;\n    height: 50px;\n\n    &.active {\n      background-color: #733de7;\n      width: 100%;\n      max-width: 125px;\n      border-radius: 24px;\n      svg {\n        margin-right: 5px;\n        path {\n          fill: #fff;\n        }\n      }\n    }\n\n    svg {\n      width: 24px;\n      height: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
