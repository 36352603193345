// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  overflow: hidden;
}
.onboarding__footer {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  width: 100%;
}
@media screen and (max-width: 362px) {
  .onboarding__footer {
    bottom: 20px;
  }
}
@media screen and (max-height: 668px) {
  .onboarding__footer {
    bottom: 20px;
  }
}
.onboarding__btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboarding__btn button {
  max-width: 345px;
  width: 100%;
  color: #000;
  padding: 16px;
  border-radius: 10px;
  background-color: #5cee9c;
  border: none;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Onboarding.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;AACJ;AAAI;EANF;IAOI,YAAA;EAGJ;AACF;AAFI;EATF;IAUI,YAAA;EAKJ;AACF;AAFE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAIJ;AAHI;EACE,gBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,yBAAA;AAKN","sourcesContent":[".onboarding {\n  width: 100%;\n  height: 100vh;\n  padding: 0 20px;\n  overflow: hidden;\n\n  &__footer {\n    position: fixed;\n    bottom: 40px;\n    left: 0;\n    right: 0;\n    width: 100%;\n    @media screen and (max-width: 362px) {\n      bottom: 20px;\n    }\n    @media screen and (max-height: 668px) {\n      bottom: 20px;\n    }\n  }\n  \n  &__btn {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    button {\n      max-width: 345px;\n      width: 100%;\n      color: #000;\n      padding: 16px;\n      border-radius: 10px;\n      background-color: #5cee9c;\n      border: none;\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
