import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import orderBy from "lodash/orderBy";
import { postEvent } from "@telegram-apps/sdk";

import { useAppDispatch } from "../../store";
import { selectUserFavourites } from "../../store/selectors/user/favourites";
import { assetsAPI } from "../../api/assets";
import { selectActiveTab } from "../../store/selectors/trade";
import { setActiveTab } from "../../store/reducers/trade";
import { setAssets } from "../../store/reducers/assets";
import useUserId from "../../hooks/userUserId";

import search from "../../icons/toolbar-search.svg";

import "./Toolbar.scss";

const tabs = [
  { id: 2, title: "All", name: "all" },
  { id: 1, title: "Favourites", name: "favourites" },
  { id: 3, title: "Popular", name: "popular" },
  // { id: 4, title: "Down", name: "down" },
  // { id: 5, title: "Up", name: "up" },
];

interface Props {
  searchValue: string;
  setSearchValue: any;
  className?: string;
}

export const Toolbar = ({ searchValue, setSearchValue, className }: Props) => {
  const [isVisibleSearch, setIsVisibleSearch] = useState(true);
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const favourites = useSelector(selectUserFavourites);
  const userId = useUserId();

  const toggleIsVisibleSearch = () => {
    setIsVisibleSearch(!isVisibleSearch);
    setSearchValue("");
  };

  const onSetActiveTab = async (name: string) => {
    dispatch(setActiveTab(name));
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };

  useEffect(() => {
    if (activeTab === "favourites") {
      dispatch(setAssets(favourites));
    }
    if (activeTab === "all") {
      (async function () {
        setTimeout(async () => {
          const { data } = await assetsAPI.getAllAssets(userId);
          dispatch(setAssets(data));
        }, 300);
      })();
    }
    if (activeTab === "popular") {
      dispatch(
        setAssets([
          {
            tag: "btc",
            name: "Bitcoin",
            symbol: "BTC",
            color: "#B87409",
          },
          {
            tag: "eth",
            name: "Ethereum",
            symbol: "ETH",
            color: "#B33FC2",
          },
          {
            tag: "bnb",
            name: "BNB",
            symbol: "BNB",
            color: "#3AE016",
          },
          {
            tag: "ton",
            name: "Ton",
            symbol: "TON",
            color: "#3ABAF6",
          },
        ])
      );
    }
    // if (activeTab === "down") {
    //   (async function () {
    //     const { data } = await assetsAPI.getAllAssets(userId);

    //     const newArr = [];

    //     data.forEach((item, i) => {
    //       fetch(
    //         `https://api.binance.com/api/v1/ticker/24hr?symbol=${item.symbol.toUpperCase()}USDT`
    //       )
    //         .then((response) => response.json())
    //         .then((data) => {
    //           const newItem = {
    //             ...item,
    //             percent: Number(data.priceChangePercent),
    //           };
    //           newArr.push(newItem);
    //         });
    //     });

    //     setTimeout(() => {
    //       const sortedArr = orderBy(newArr, "percent", "asc");

    //       dispatch(setAssets(sortedArr));
    //     }, 500);
    //   })();
    // }
    // if (activeTab === "up") {
    //   (async function () {
    //     const { data } = await assetsAPI.getAllAssets(userId);
    //     const newArr = [];

    //     data.forEach((item, i) => {
    //       fetch(
    //         `https://api.binance.com/api/v1/ticker/24hr?symbol=${item.symbol.toUpperCase()}USDT`
    //       )
    //         .then((response) => response.json())
    //         .then((data) => {
    //           const newItem = {
    //             ...item,
    //             percent: Number(data.priceChangePercent),
    //           };
    //           newArr.push(newItem);
    //         });
    //     });

    //     setTimeout(() => {
    //       const sortedArr = orderBy(newArr, "percent", "desc");

    //       dispatch(setAssets(sortedArr));
    //     }, 500);
    //   })();
    // }
  }, [activeTab]);

  useEffect(() => {
    dispatch(setActiveTab("all"));
  }, [dispatch, setActiveTab]);

  return (
    <div className={classNames("toolbar", className)}>
      <div className="toolbar__header">
        {isVisibleSearch ? (
          <>
            <div className="toolbar__header-loupe">
              <img src={search} alt="поиск" onClick={toggleIsVisibleSearch} />
            </div>
            <div className="toolbar__header-tabs">
              {tabs.map((item) => (
                <div
                  className={classNames(
                    "toolbar__header-tab",
                    activeTab === item.name ? "active" : ""
                  )}
                  key={item.id}
                  onClick={() => onSetActiveTab(item.name)}
                >
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="toolbar__header-search">
            <input
              placeholder="Search"
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
            <span onClick={toggleIsVisibleSearch}>Return</span>
          </div>
        )}
      </div>
    </div>
  );
};
