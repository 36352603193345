import { motion } from "framer-motion";
import { Steps } from "../../Steps";
import { OnboardingHeader } from "../../Header";
import TradeOpenPosition from "../../../../components/TradeOpenPosition";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store";
import { setIsOnboarding } from "../../../../store/reducers/loading";
import "./Ninth.scss";

export const NinthScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onNavigate = (path: string) => {
    navigate(path);
    dispatch(setIsOnboarding(false));
  };
  return (
    <motion.div
      className="ninth-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="ninth-screen__header">
        <OnboardingHeader />
      </div>
      <div className="ninth-screen__main">
        <div className="ninth-screen__coin">
          <TradeSelect />
        </div>
        <motion.div variants={animationCenter} custom={1.5}>
          <TradeOpenPosition />
        </motion.div>
      </div>
      <div className="onboarding__footer">
        <motion.div variants={animationCenter} custom={1.5}>
          <Steps
            path="/"
            title="Open position"
            subtitle="Проверяйте общий и обычный баланс"
            page="5"
            isSkipping={true}
            withoutButton={true}
          />
        </motion.div>
        <div className="onboarding__btn">
          <button onClick={() => onNavigate("/")}>Start trading</button>
        </div>
      </div>
    </motion.div>
  );
};
