import { useCallback } from "react";
import { useSelector } from "react-redux";

import { assetsAPI } from "../api/assets";
import { setUserPositions } from "../store/reducers/user/positions";
import { selectUser } from "../store/selectors/user";
import { useAppDispatch } from "../store";
import useUserId from "./userUserId";

export const useUpdatePositions = (
  initDataRaw: any,
  assetType: "close" | "open"
) => {
  const dispatch = useAppDispatch();
  // const user = useSelector(selectUser);
  const userId = useUserId();
  const updatePositions = useCallback(async () => {
    const someArray: any = [];
    const { data } = await assetsAPI.getPositions(
      userId,
      initDataRaw,
      assetType
    );

    data.map((item: any) => {
      const token: any = item;

      if (Number(token.amount) > 0) {
        someArray.push(token);
      }
    });
    dispatch(setUserPositions(someArray));
  }, [setUserPositions]);
  return updatePositions;
};
