// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-modal {
  min-height: 400px;
  background-color: #242830;
  padding: 70px 15px 15px 15px;
}
.info-modal p {
  color: #bfbfbf;
  font-weight: 500;
}
.info-modal__graph {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoModal/InfoModal.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;EACA,4BAAA;AACF;AAAE;EACE,cAAA;EACA,gBAAA;AAEJ;AAAE;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".info-modal {\n  min-height: 400px;\n  background-color: #242830;\n  padding: 70px 15px 15px 15px;\n  p {\n    color: #bfbfbf;\n    font-weight: 500;\n  }\n  &__graph {\n    width: 100%;\n    margin-top: 15px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
