// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sixth-screen__balance {
  display: flex;
  margin-bottom: 10px;
}
.sixth-screen__search {
  margin-top: 40px;
  overflow-x: scroll;
  margin-bottom: 5px;
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Sixth/Sixth.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,oBAAA;AAAJ","sourcesContent":[".sixth-screen {\n  &__balance {\n    display: flex;\n    margin-bottom: 10px;\n  }\n  &__search {\n    margin-top: 40px;\n    overflow-x: scroll;\n    margin-bottom: 5px;\n    padding-bottom: 15px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
