import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";

import {
  animationCenter,
  animationLeft,
  animationRight,
  animationTop,
} from "../../utils/animation";

import Drawer from "@mui/material/Drawer";

import calendar from "../../icons/earn_calendar.svg";
import arrow from "../../icons/earn-arrow.svg";
import youtube from "../../icons/earn_youtube.svg";
import insta from "../../icons/earn_insta.svg";
import close from "../../icons/close-icon.png";
import claimed from "../../icons/checked.svg";
import tg from "../../icons/earn_tg.svg";
import copy from "../../icons/ref_copy.svg";
import copied from "../../icons/ref_copied.svg";
import avatarMock from "../../icons/avatar-mock.svg";
import arrowRight from "../../icons/earn-arrow-right.svg";

import "./Earn.scss";
import { useAppDispatch } from "../../store";
import { setIsVisibleEarnDailyModal } from "../../store/reducers/modals";
import { EarnDailyModal } from "../../components/EarnDailyModal";
import {
  claimSubReward,
  fetchSubRewards,
} from "../../store/actionCreators/earn";
import useUserId from "../../hooks/userUserId";
import { useSelector } from "react-redux";
import { selectSubRewards } from "../../store/selectors/earn";
import { postEvent, retrieveLaunchParams } from "@telegram-apps/sdk";
import { ProfileCard } from "../../components/ProfileCard";
import { setFriends } from "../../store/reducers/user";
import { userAPI } from "../../api/user";
import { selectUserFriends } from "../../store/selectors/user";
import { toast } from "react-toastify";
import classNames from "classnames";

export default function Earn() {
  const dispatch = useAppDispatch();
  const userId = useUserId();
  const pageRef = useRef<any>();
  const { initDataRaw } = retrieveLaunchParams();

  const subRewards = useSelector(selectSubRewards);
  const friends = useSelector(selectUserFriends);

  const [isYoutube, setIsYoutube] = useState(false);
  const [isInsta, setIsInsta] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isRefTg, setIsRefTg] = useState(false);

  const link = "https://t.me/demotradeapp_bot";

  const toggleModal = (newOpen: boolean) => () => {
    setIsRefTg(newOpen);
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };
  function copyClipboard() {
    navigator.clipboard.writeText(link);
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
    toast.success(`Link copied!`, { position: "top-center" });
  }

  const toggleIsYoutubeDrawer = (newOpen: boolean) => () => {
    if (subRewards.task_youtube) {
      return;
    }
    setIsYoutube(newOpen);
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };
  const toggleIsInsta = (newOpen: boolean) => () => {
    if (subRewards.task_insta) {
      return;
    }
    setIsInsta(newOpen);
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };

  const onClickYoutubeClaim = () => {
    dispatch(claimSubReward(userId, "task_youtube", initDataRaw));
    setIsYoutube(false);
  };

  const onClickInstaClaim = () => {
    dispatch(claimSubReward(userId, "task_insta", initDataRaw));
    setIsInsta(false);
  };

  const onDailyRewardClick = () => {
    dispatch(setIsVisibleEarnDailyModal(true));
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };

  useEffect(() => {
    dispatch(fetchSubRewards(userId, initDataRaw));
    (async function () {
      const { data } = await userAPI.getFriends(userId, initDataRaw);

      dispatch(setFriends(data.friends));
    })();
  }, [dispatch]);

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      variants={animationCenter}
      ref={pageRef}
      className="earn"
    >
      <div className="bg"></div>
      <ProfileCard />
      <motion.h2 variants={animationTop} custom={2} className="earn__title">
        Earn more USDT
      </motion.h2>
      <motion.p variants={animationTop} custom={2} className="earn__subtitle">
        Complete tasks and get rewards
      </motion.p>
      <motion.div variants={animationLeft} custom={1.4} className="earn__daily">
        <div className="earn__item  widget-card" onClick={onDailyRewardClick}>
          <img src={calendar} alt="" className="earn__item_img" />
          <p className="earn__item_title">Daily reward</p>
          <p className="earn__item_price green">
            <span>10,000</span>USDT
          </p>
        </div>
      </motion.div>
      <div className="earn__tasks">
        <motion.p
          variants={animationTop}
          custom={1}
          className="earn__tasks-title"
        >
          Current tasks
        </motion.p>
        <motion.div
          variants={animationRight}
          custom={1.4}
          className="earn__item widget-card"
          onClick={toggleIsYoutubeDrawer(true)}
        >
          <img src={youtube} alt="" className="earn__item_img" />
          <div className="earn__item_wrapper">
            <p className="earn__item_title">Watch video</p>
            <p className="earn__item_price">
              <span>10,000</span>USDT
            </p>
          </div>
          <div style={{ marginLeft: "auto", marginRight: 10 }}>
            <img src={arrowRight} alt="" width={8} height={8} />
          </div>
        </motion.div>
        <motion.div
          variants={animationRight}
          custom={1.7}
          className="earn__item widget-card"
          // onTouchEnd={toggleIsInsta(true)}
          onClick={toggleIsInsta(true)}
        >
          <img src={insta} alt="" className="earn__item_img" />
          <div className="earn__item_wrapper">
            <p className="earn__item_title">Join our instagram</p>
            <p className="earn__item_price">
              <span>10,000</span>USDT
            </p>
          </div>

          <div style={{ marginLeft: "auto", marginRight: 10 }}>
            <img src={arrowRight} alt="" width={8} height={8} />
          </div>
        </motion.div>
        <motion.div
          variants={animationLeft}
          custom={1.4}
          className="earn__refferals-item widget-card"
          onClick={toggleModal(true)}
        >
          <img src={tg} alt="телеграмм" />
          <div className="earn__refferals-item-body">
            <span>Invite friend</span>
            <p>10,000 USDT for both users</p>
          </div>
          <div style={{ marginLeft: "auto", marginRight: 10 }}>
            <img src={arrowRight} alt="" width={8} height={8} />
          </div>
        </motion.div>
      </div>
      <div className="earn__friends">
        <div className="earn__friends-title">Already invited</div>

        <div className="earn__friends-items widget-card">
          {friends?.length ? (
            friends?.map((item) => (
              <div className="earn__friends-item">
                <img src={avatarMock} alt="" width={40} height={40} />
                <div className="earn__friends-item-info">
                  <p>{item.username}</p>
                  <span>
                    Lvl {item.trade_lvl.lvl}/10: {item.trade_lvl.name}{" "}
                  </span>
                </div>
                <div
                  className={classNames(
                    "earn__friends-item-balance",
                    item.total_pnl < 0 ? "red" : ""
                  )}
                >
                  <p>{item.usdt} USDT</p>
                  <span>
                    {item.total_pnl < 0
                      ? `(${item.total_pnl}%)`
                      : `(+${item.total_pnl}%)`}{" "}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <motion.div
              variants={animationRight}
              style={{ textAlign: "center" }}
            >
              <p>You haven’t invited any friends yet</p>
            </motion.div>
          )}
        </div>
      </div>
      <div>
        <EarnDailyModal />
        <Drawer
          open={isYoutube}
          anchor="bottom"
          onClose={toggleIsYoutubeDrawer(false)}
        >
          <div className="earn__modal">
            <button onClick={toggleIsYoutubeDrawer(false)}>
              <img src={close} alt="" className="earn__modal_close" />
            </button>
            <div className="earn__modal_line"></div>
            <img src={youtube} alt="" className="earn__modal_img" />
            <p className="earn__modal_title">Watch video</p>
            <p className="earn__modal_subtitle">
              <p className="earn__item_price">
                <img src={arrow} alt="" />
                <span>10,000 </span> USDT
              </p>
            </p>
            <a
              href="https://youtube.com"
              target="_blank"
              onClick={onClickYoutubeClaim}
            >
              <button className="earn__modal_btn">Continue</button>
            </a>

            <div className="earn__modal_bottom"></div>
          </div>
        </Drawer>
        <Drawer open={isInsta} anchor="bottom" onClose={toggleIsInsta(false)}>
          <div className="earn__modal">
            <button onClick={toggleIsInsta(false)}>
              <img src={close} alt="" className="earn__modal_close" />
            </button>
            <div className="earn__modal_line"></div>
            <img src={insta} alt="" className="earn__modal_img" />
            <p className="earn__modal_title">Join our instagram</p>
            <p className="earn__modal_subtitle">
              <p className="earn__item_price">
                <span>10,000 </span> USDT
              </p>
            </p>
            <a
              href="https://instagram.com"
              target="_blank"
              onClick={onClickInstaClaim}
            >
              <button className="earn__modal_btn">Continue</button>
            </a>
            <div className="earn__modal_bottom"></div>
          </div>
        </Drawer>
        <Drawer open={isRefTg} anchor="bottom" onClose={toggleModal(false)}>
          <div className="earn__modal">
            <button onClick={toggleModal(false)}>
              <img src={close} alt="" className="earn__modal_close" />
            </button>
            <div className="earn__modal_line"></div>
            <p className="earn__modal_title">Invite a friend</p>
            <p className="earn__modal_subtitle">
              <p className="earn__item_price">
                <span>10,000 </span> USDT for you and your friend
              </p>
            </p>
            <div className="earn__modal__btns">
              <a
                href={`https://t.me/share/url?url=https://t.me/demotradeapp_bot?start=${userId}`}
                target="_blank"
                rel="noreferrer"
                className="earn__modal_btn earn__modal_btn_ref"
              >
                <button>Invite in Telegram</button>
              </a>

              <button
                className="earn__modal_btn"
                style={{ backgroundColor: "#EBEBEB", color: "#242830" }}
                onClick={copyClipboard}
              >
                Copy link
              </button>
            </div>
            <div className="earn__modal_bottom"></div>
          </div>
        </Drawer>
      </div>
    </motion.div>
  );
}
