// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seventh-screen {
  width: 100%;
  height: 100vh;
  position: relative;
}
.seventh-screen__coin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 300px;
  left: 0;
  right: 0;
}
.seventh-screen__coin span {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Seventh/Seventh.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACN","sourcesContent":[".seventh-screen {\n  width: 100%;\n  height: 100vh;\n  position: relative;\n\n  &__coin {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    bottom: 300px;\n    left: 0;\n    right: 0;\n    \n    span {\n      font-size: 24px;\n      font-weight: 500;\n      color: #ffffff;\n      margin-left: 5px;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
