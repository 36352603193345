import { RootState } from "../../index";

export const selectUserTotalBalance = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.totalBalance;
export const selectHasOpenedPositions = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.hasOpenedPositions;
export const selectUserBalanceLoading = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.isLoading;
export const selectCurrentValue = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.currentValue;
export const selectPercentChange = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.percentChange;
export const selectProfitChange = ({ userBalanceReducer }: RootState) =>
  userBalanceReducer.profitChange;
