import { AppDispatch } from "../../";
import { assetsAPI } from "../../../api/assets";
import { UserType } from "../../../types/user";
import {
  setUserClosedAssets,
  setUserOpenAssets,
} from "../../reducers/user/assets";

export const fetchUserOpenAssets =
  (userId: number, initDataRaw: any, assetType: "open") =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await assetsAPI.getPositions(
        userId,
        initDataRaw,
        assetType
      );

      dispatch(setUserOpenAssets(data));
    } catch (error) {
      console.log(error);
    }
  };
export const fetchUserClosedAssets =
  (userId: number, initDataRaw: any, assetType: "close") =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await assetsAPI.getPositions(
        userId,
        initDataRaw,
        assetType
      );

      dispatch(setUserClosedAssets(data));
    } catch (error) {
      console.log(error);
    }
  };
