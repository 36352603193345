import { useEffect, useMemo, useState, useRef, useLayoutEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { animationTop, animationBottom } from "../../utils/animation";
import { selectSelectedTradeValue } from "../../store/selectors/trade";
import { useAppDispatch } from "../../store";
import { assetsAPI } from "../../api/assets";
import {
  addToFavourites,
  removeFromFavourites,
} from "../../store/reducers/user/favourites";
import { selectUserFavourites } from "../../store/selectors/user/favourites";
import { selectAssets } from "../../store/selectors/assets";
import { setAssets } from "../../store/reducers/assets";
import { setIsVisibleInfoModal } from "../../store/reducers/modals";
import useUserId from "../../hooks/userUserId";

import favorite from "../../icons/favorite.svg";
import favouriteAdded from "../../icons/toolbar-favourite.svg";
import info from "../../icons/onboarding-info.svg";
import tetherIcon from "../../icons/tether.svg";

import TradingViewWidget from "../../components/ConstructorChart/TradingViewWidget";
import TradeSelect from "../../components/TradeSelect";
import { TradeModal } from "../../components/TradeModal";
import { Terminal } from "../../components/ConstructorChart/Terminal";

import "./Trade.scss";
import { selectTgUser } from "../../store/selectors/user/tg";

export const Trade = () => {
  const dispatch = useAppDispatch();
  const pageRef = useRef<any>();
  const userId = useUserId();

  const selectedTradeValue = useSelector(selectSelectedTradeValue);
  const assets = useSelector(selectAssets);
  const tgUser = useSelector(selectTgUser);

  const [price, setPrice] = useState<any>("0");
  const [last24Change, setLast24Change] = useState("0.00");
  const [searchValue, setSearchValue] = useState<string>("");
  const favourites = useSelector(selectUserFavourites);

  const lastDayResult = useMemo(() => {
    const parsedLast24Change = Number(last24Change).toFixed(2);

    return Number(last24Change) > 0 ? +parsedLast24Change : parsedLast24Change;
  }, [last24Change]);

  const onAddToFavourites = () => {
    dispatch(addToFavourites(selectedTradeValue));
  };

  const onRemoveFromFavourites = () => {
    dispatch(removeFromFavourites(selectedTradeValue?.name));
  };

  const onInfoClick = () => {
    dispatch(setIsVisibleInfoModal(true));
  };

  useEffect(() => {
    (async () => {
      const { data } = await assetsAPI.getAllAssets(userId);

      const filteredAssets = data.filter(
        (item) =>
          item.symbol.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );

      dispatch(setAssets(filteredAssets));
    })();
  }, [searchValue]);

  useEffect(() => {
    setPrice("0");

    if (selectedTradeValue) {
      fetch(
        `https://api.binance.com/api/v1/ticker/24hr?symbol=${selectedTradeValue.symbol.toUpperCase()}USDT`
      )
        .then((response) => response.json())
        .then((data) => {
          setPrice(
            data.symbol === "PEPEUSDT"
              ? Number(data.lastPrice)
              : Number(data.lastPrice).toFixed(2)
          );
          setLast24Change(data.priceChangePercent);
        });
    }
  }, [selectedTradeValue]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${selectedTradeValue?.symbol.toLowerCase()}usdt@ticker`
    );

    ws.onopen = () => {
      ws.onmessage = (event) => {
        const { data } = JSON.parse(event.data);

        setPrice(
          data.s === "PEPEUSDT" ? Number(data.c) : Number(data.c).toFixed(2)
        );
        setLast24Change(data.P);
      };
    };
    return () => {
      ws.close();
    };
  }, [selectedTradeValue]);

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      ref={pageRef}
      className="trade"
    >
      <div className="bg"></div>
      <motion.div
        custom={1.4}
        variants={animationTop}
        className="trade__header"
      >
        <TradeSelect />
        {favourites.find((item) => item.name === selectedTradeValue?.name) ? (
          <button
            className="trade__header_favorite"
            onClick={onRemoveFromFavourites}
          >
            <img src={favouriteAdded} alt="" />
          </button>
        ) : (
          <button
            className="trade__header_favorite"
            onClick={onAddToFavourites}
          >
            <img src={favorite} alt="" />
          </button>
        )}
      </motion.div>
      <motion.div custom={1} variants={animationTop} className="trade__info">
        <div className="trade__info_up">
          <div className="trade__info_up_number">{price}</div>
        </div>
        <div className="trade__info_down">
          <div
            className={classNames(
              "trade__info_down_changes",
              Number(last24Change) < 0 ? "trade__info_down_changes_red" : ""
            )}
          >
            {Number(last24Change) > 0
              ? `+${last24Change} USDT (+${lastDayResult}%) Today`
              : `${last24Change} USDT (${lastDayResult}%) Today`}
          </div>
        </div>
      </motion.div>
      <motion.div custom={2} variants={animationBottom}>
        <Accordion className="trade__chart">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h2 style={{ fontWeight: 600 }}>Chart</h2>
          </AccordionSummary>
          <AccordionDetails>
            {selectedTradeValue && (
              <TradingViewWidget
                isOpen={true}
                currentToken={selectedTradeValue}
                withTerminal={false}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </motion.div>

      <motion.div
        custom={2.4}
        variants={animationBottom}
        className="trade__terminal widget-card"
      >
        <div className="trade__terminal-header">
          <h2>Trading</h2>
          <div className="trade__terminal-header-market">
            <span>MARKET</span>
            <img
              src={info}
              alt=""
              width={24}
              height={24}
              onClick={onInfoClick}
            />
          </div>
        </div>
        <div className="trade__terminal-available">
          <img src={tetherIcon} alt="" />
          <div className="trade__terminal-available-balance">
            <p>{tgUser?.usdt}</p>
            <span>Available</span>
          </div>
        </div>
        {selectedTradeValue && (
          <Terminal currentPrice={price} currentToken={selectedTradeValue} />
        )}
      </motion.div>
      <TradeModal
        assets={assets}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </motion.div>
  );
};
