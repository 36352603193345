import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { selectUser } from "../../store/selectors/user";
import { AssetItem } from "../../types/user/assets";
import favouriteAdded from "../../icons/toolbar-favourite.svg";

import { ReactComponent as ArrowSvg } from "../../icons/arrow.svg";

import Skeleton from "../Skeleton";
import TradingViewWidget from "../ConstructorChart/TradingViewWidget";

import "./AssetRow.scss";
import { selectActiveTab } from "../../store/selectors/trade";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import {
  setIsVisibleTradeModal,
  setSelectedTradeValue,
} from "../../store/reducers/trade";
import { postEvent } from "@telegram-apps/sdk";

interface AssetRowProps {
  currentToken: AssetItem;
  delay: number;
  className?: string;
}

export const AssetRow = ({ currentToken, delay, className }: AssetRowProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useSelector(selectActiveTab);

  const [last24Change, setLast24Change] = useState("0.00");
  const [price, setPrice] = useState<any>("0");

  const onCoinClick = () => {
    if (location.pathname === "/trade") {
      dispatch(setSelectedTradeValue(currentToken));
      dispatch(setIsVisibleTradeModal(false));
    }
    if (location.pathname === "/") {
      dispatch(setSelectedTradeValue(currentToken));
      // dispatch(setIsVisibleTradeModal(false));
      navigate("/trade");
    }
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };

  useEffect(() => {
    setPrice("0");
    setLast24Change("0.00");

    fetch(
      `https://api.binance.com/api/v1/ticker/24hr?symbol=${currentToken?.symbol.toUpperCase()}USDT`
    )
      .then((response) => response.json())
      .then((data) => {
        setPrice(
          data.symbol === "PEPEUSDT"
            ? Number(data.lastPrice)
            : Number(data.lastPrice).toFixed(4)
        );
        setLast24Change(data.priceChangePercent);
      });
  }, [currentToken]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${currentToken?.symbol.toLowerCase()}usdt@ticker`
    );

    ws.onopen = () => {
      ws.onmessage = (event) => {
        const { data } = JSON.parse(event.data);

        setPrice(
          data.s === "PEPEUSDT" ? Number(data.c) : Number(data.c).toFixed(4)
        );
        setLast24Change(data.P);
      };
    };
    return () => {
      ws.close();
    };
  }, []);

  const lastDayResult = useMemo(() => {
    const parsedLast24Change = Number(last24Change).toFixed(2);

    return Number(last24Change) > 0 ? +parsedLast24Change : parsedLast24Change;
  }, [last24Change]);

  if (!price) return null;

  return (
    <div className={classNames("asset-list__row")} onClick={onCoinClick}>
      <div className={classNames("asset-list__row-info", className)}>
        <div className="asset-list__name">
          <div className="asset-list__name-flex">
            {activeTab === "favourites" ? (
              <img className="asset-list__icon" src={favouriteAdded} alt="" />
            ) : (
              <img
                className="asset-list__icon"
                src={`cryptoicons/${currentToken?.symbol}.png`}
                alt=""
              />
            )}
            <div>
              <span className="asset-list__token-symbol">
                {currentToken?.symbol}
              </span>
              <p className="asset-list__token-name">{currentToken?.name}</p>
            </div>
          </div>
        </div>

        <div className="asset-list__price">
          {`${Number(price).toFixed(6)} $`}
        </div>

        <div
          className={classNames(
            "asset-list__percent",
            Number(last24Change) < 0 ? "asset-list__percent_red" : ""
          )}
        >
          {Number(last24Change) < 0
            ? `${Number(last24Change).toFixed(2)}%`
            : `+${Number(last24Change).toFixed(2)}%`}
        </div>
      </div>
    </div>
  );
};
