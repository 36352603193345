import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import Modal from "react-modal";
import { SDKProvider, useLaunchParams } from "@telegram-apps/sdk-react";

import { persistor, store } from "./store";
import { wagmiConfig } from "./config/wagmi";

import { App } from "./App";

import "./index.scss";
import { PersistGate } from "redux-persist/integration/react";
import "./mockTg";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <SDKProvider acceptCustomStyles debug={true}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
            <App />
          </WagmiConfig>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </SDKProvider>
);
