// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card {
  padding: 15px 0;
  border-radius: 0px 0px 20px 20px;
  position: relative;
  z-index: 999;
}
.profile-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  position: relative;
  z-index: 999;
}
.profile-card__header-wrap {
  display: flex;
}
.profile-card__header-info {
  display: flex;
  align-items: center;
  color: #fff;
}
.profile-card__header-info span {
  font-weight: 600;
}
.profile-card__header-info p {
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}
.profile-card__header-info-progress {
  background-color: rgba(0, 0, 0, 0.1490196078);
  height: 5px;
  width: 115px;
  border-radius: 26px;
}
.profile-card__header-info-progress-value {
  width: 20px;
  height: 5px;
  background-color: #5cee9c;
  border-radius: 26px;
}
.profile-card__main-block {
  border: 1px solid #bfbfbf;
  padding: 5px 10px 5px 10px;
  border-radius: 50px;
  font-size: 14px;
  word-break: break-word;
}
.profile-card__main-block p {
  color: #bfbfbf;
}
.profile-card__main-block p span {
  color: #fff;
}
.profile-card__main-wrap {
  display: flex;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileCard/ProfileCard.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gCAAA;EACA,kBAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAGN;AAFM;EACE,gBAAA;AAIR;AAFM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAIR;AAFM;EACE,6CAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAIR;AAHQ;EACE,WAAA;EACA,WAAA;EACA,yBAAA;EACA,mBAAA;AAKV;AACI;EACE,yBAAA;EACA,0BAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;AACN;AAAM;EACE,cAAA;AAER;AADQ;EACE,WAAA;AAGV;AACI;EACE,aAAA;EACA,kBAAA;AACN","sourcesContent":[".profile-card {\n  padding: 15px 0;\n  border-radius: 0px 0px 20px 20px;\n  position: relative;\n  z-index: 999;\n  &__header {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 5px;\n    position: relative;\n    z-index: 999;\n    &-wrap {\n      display: flex;\n    }\n    &-info {\n      display: flex;\n      align-items: center;\n      color: #fff;\n      span {\n        font-weight: 600;\n      }\n      p {\n        color: #bfbfbf;\n        font-size: 14px;\n        font-weight: 500;\n        margin-top: 5px;\n        margin-bottom: 5px;\n      }\n      &-progress {\n        background-color: #00000026;\n        height: 5px;\n        width: 115px;\n        border-radius: 26px;\n        &-value {\n          width: 20px;\n          height: 5px;\n          background-color: #5cee9c;\n          border-radius: 26px;\n        }\n      }\n    }\n  }\n  &__main {\n    &-block {\n      border: 1px solid #bfbfbf;\n      padding: 5px 10px 5px 10px;\n      border-radius: 50px;\n      font-size: 14px;\n      word-break: break-word;\n      p {\n        color: #bfbfbf;\n        span {\n          color: #fff;\n        }\n      }\n    }\n    &-wrap {\n      display: flex;\n      margin-bottom: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
