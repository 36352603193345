import { AppDispatch } from "../";
import { earnAPI } from "../../api/earn";
import { setDailyRewards, setSubRewards } from "../reducers/earn";

export const fetchDailyRewards =
  (userId: number, initDataRaw: any) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await earnAPI.fetchDailyRewards(userId, initDataRaw);

      dispatch(setDailyRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
export const claimDailyReward =
  (userId: number, day: string, initDataRaw: any) =>
  async (dispatch: AppDispatch) => {
    try {
      await earnAPI.claimDailyReward(userId, day, initDataRaw);

      // dispatch(setDailyRewards(data));
      dispatch(fetchDailyRewards(userId, initDataRaw));
    } catch (error) {
      console.log(error);
    }
  };
export const fetchSubRewards =
  (userId: number, initDataRaw: any) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await earnAPI.fetchSubRewards(userId, initDataRaw);

      dispatch(setSubRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
export const claimSubReward =
  (userId: number, task: string, initDataRaw: any) =>
  async (dispatch: AppDispatch) => {
    try {
      await earnAPI.claimSubReward(userId, task, initDataRaw);

      dispatch(fetchSubRewards(userId, initDataRaw));

      // dispatch(setSubRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
