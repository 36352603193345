// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ninth-screen {
  width: 100%;
  height: 100%;
}
.ninth-screen__main {
  display: flex;
  flex-direction: column;
  gap: 63px;
  width: 100%;
}
@media screen and (max-height: 668px) {
  .ninth-screen__main {
    gap: 30px;
  }
}
.ninth-screen__coin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ninth-screen__coin span {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Ninth/Ninth.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AACJ;AAAI;EALF;IAMI,SAAA;EAGJ;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AAGN","sourcesContent":[".ninth-screen {\n  width: 100%;\n  height: 100%;\n  \n  &__main {\n    display: flex;\n    flex-direction: column;\n    gap: 63px;\n    width: 100%;\n    @media screen and (max-height: 668px) {\n      gap: 30px;\n    }\n  }\n\n  &__coin {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    span {\n      font-size: 24px;\n      font-weight: 500;\n      color: #ffffff;\n      margin-left: 5px;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
