import { useInitData } from "@vkruglikov/react-telegram-web-app";

import { retrieveLaunchParams } from "@telegram-apps/sdk";

export default function useUserId() {
  const [initDataUnsafe] = useInitData();
  // const { initData } = retrieveLaunchParams();

  const userId = initDataUnsafe?.user?.id
    ? initDataUnsafe?.user?.id
    : 7437457432;
  return userId;
}
