import { useState } from "react";
import { AppDispatch } from "../";
import { assetsAPI } from "../../api/assets";
import { setAssets, setTestAssets } from "../reducers/assets";
import axios from "axios";

export const fetchTestAssets = () => async (dispatch: AppDispatch) => {
  try {
    // const { data } = await assetsAPI.getAllAssets();
    // const [assets, setAssets] = useState([]);
    const items: any = [
      {
        tag: "btc",
        name: "Bitcoin",
        symbol: "BTC",
        color: "#B87409",
      },
      {
        tag: "eth",
        name: "Ethereum",
        symbol: "ETH",
        color: "#B33FC2",
      },
      {
        tag: "bnb",
        name: "BNB",
        symbol: "BNB",
        color: "#3AE016",
      },
    ];

    for (let item of items) {
      const { data } = await axios.get(
        `https://api.binance.com/api/v1/ticker/24hr?symbol=${item.symbol.toUpperCase()}USDT`
      );
      Object.isExtensible(item);

      item.percent = Number(data.priceChangePercent);
      item.price = Number(data.lastPrice);

      const ws = new WebSocket(
        `wss://fstream.binance.com/stream?streams=${item?.symbol.toLowerCase()}usdt@ticker`
      );

      // let obg = {};
    }

    dispatch(setTestAssets(items));
  } catch (error) {
    console.log(error);
  }
};
