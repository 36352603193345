// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.second-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.second-screen__balance {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Second/Second.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":[".second-screen {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n\n  &__balance {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
