import React from "react";
import { motion } from "framer-motion";
import expandIcon from "../../icons/wheel-expand.svg";
import avatarMock from "../../icons/avatar-mock.svg";

import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";

import "./ProfileCard.scss";
import { animationTop } from "../../utils/animation";

export const ProfileCard = () => {
  const tgUser = useSelector(selectTgUser);
  return (
    <motion.div className="profile-card" custom={2} variants={animationTop}>
      {/* <div className="bg"></div> */}
      <div className="profile-card__header">
        <div className="profile-card__header-wrap">
          <img
            src={avatarMock}
            alt="аватарка"
            className="profile-card__header-avatar"
          />
          <div className="profile-card__header-info">
            <div style={{ marginLeft: 10, marginRight: 5 }}>
              <span>{tgUser?.username ? tgUser?.username : "John"}</span>
              <p>Lvl {tgUser?.trade_lvl?.lvl}/10</p>
              <div className="profile-card__header-info-progress">
                <div className="profile-card__header-info-progress-value"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-card__header-wheel">
          <img src={expandIcon} alt="колесо фортуны" />
        </div>
      </div>
      {/* <div className="profile__main">
        <div className="profile__main-wrap">
          <div className="profile__main-block" style={{ marginRight: 5 }}>
            <p>
              <span>{tgUser?.trade_lvl.name}</span>
            </p>
          </div>
          <div className="profile__main-block">
            <p>
              Comission fees: <span>{tgUser?.trade_lvl.commission}%</span> (4,5%
              on next lvl)
            </p>
          </div>
        </div>

        <div className="profile__main-block">
          <p>
            Required trading volume for next level: <span>400/1000₮</span>{" "}
          </p>
        </div>
      </div> */}
    </motion.div>
  );
};
