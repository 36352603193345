import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
  animationBottom,
  animationRight,
  animationTop,
} from "../../utils/animation";
import { assetsAPI } from "../../api/assets";

import recording from "../../icons/lb_record.svg";
import first from "../../icons/lb_first.svg";
import second from "../../icons/lb_second.svg";
import third from "../../icons/lb_third.svg";

import arrowUp from "../../icons/earn-arrow.svg";
import arrowDown from "../../icons/percent-arrow-down.svg";

import "./RatingTable.scss";
import useUserId from "../../hooks/userUserId";
import classNames from "classnames";
import { Toolbar } from "../Toolbar";
import { RatingToolbar } from "../RatingToolbar";
import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";

export const RatingTable = () => {
  const [users, setUsers] = useState(null);
  const tgUser = useSelector(selectTgUser);

  useEffect(() => {
    (async function () {
      const { data } = await assetsAPI.getTopUsers(tgUser?.trade_lvl.lvl);

      console.log(data);

      setUsers(data.top_users);
    })();
  }, [setUsers]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className="rating"
    >
      <div className="rating-title">
        <motion.h2 custom={2} variants={animationTop} style={{ color: "#fff" }}>
          Rating Table
        </motion.h2>
        <motion.p custom={2} variants={animationTop} style={{ color: "#fff" }}>
          09:43 minutes untill update
        </motion.p>
      </div>
      <motion.div
        className="rating__items-wrap widget-card"
        variants={animationBottom}
        custom={1.2}
      >
        <RatingToolbar />
        <div className="rating-items__header">
          <p>Rating / Name</p>
          <p>Balance (USDT) / PNL</p>
        </div>
        <div className="rating-items">
          {users?.map((item, i) => {
            const place = i + 1;
            return (
              <div className="rating__item" key={item.user_id}>
                <div className="rating__place">
                  {/* {place === 1 ? (
                  <img src={first} alt="" />
                ) : place === 2 ? (
                  <img src={second} alt="" />
                ) : place === 3 ? (
                  <img src={first} alt="" />
                ) : ( */}
                  <span>{place}</span>
                  {/* )}  */}
                </div>
                <p className="rating__name">{item.username}</p>
                <p className="rating__balance">{item.balance.toFixed(2)}</p>
                <p
                  className={classNames(
                    "rating__profit",
                    item.total_pnl < 0 ? "rating__profit_red" : ""
                  )}
                >
                  {item.total_pnl >= 0 ? (
                    <img src={arrowUp} alt="" />
                  ) : (
                    <img src={arrowDown} alt="" />
                  )}
                  {item.total_pnl > 0
                    ? `+${item.total_pnl}%`
                    : `${item.total_pnl}%`}
                </p>
              </div>
            );
          })}
        </div>
      </motion.div>
    </motion.div>
  );
};
