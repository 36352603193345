// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fifth-screen {
  width: 100%;
  height: 100%;
}
.fifth-screen__balance {
  margin-bottom: 10px;
  display: flex;
}
.fifth-screen__coin {
  box-shadow: -5px -4px 20px 0px rgba(129, 161, 224, 0.3411764706);
}
.fifth-screen__coin-wrap {
  padding: 20px;
  margin-top: 90px;
}
@media screen and (max-width: 362px) {
  .fifth-screen__coin-wrap {
    padding: 10px;
    margin-top: 0;
  }
}
@media screen and (max-height: 668px) {
  .fifth-screen__coin-wrap {
    padding: 10px;
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Fifth/Fifth.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;AACJ;AACE;EACE,gEAAA;AACJ;AACI;EACE,aAAA;EACA,gBAAA;AACN;AAAM;EAHF;IAII,aAAA;IACA,aAAA;EAGN;AACF;AAFM;EAPF;IAQI,aAAA;IACA,aAAA;EAKN;AACF","sourcesContent":[".fifth-screen {\n  width: 100%;\n  height: 100%;\n\n  &__balance {\n    margin-bottom: 10px;\n    display: flex;\n  }\n  &__coin {\n    box-shadow: -5px -4px 20px 0px #81a1e057;\n\n    &-wrap {\n      padding: 20px;\n      margin-top: 90px;\n      @media screen and (max-width: 362px) {\n        padding: 10px;\n        margin-top: 0;\n      }\n      @media screen and (max-height: 668px) {\n        padding: 10px;\n        margin-top: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
