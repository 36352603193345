import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import orderBy from "lodash/orderBy";
import { postEvent } from "@telegram-apps/sdk";

import { useAppDispatch } from "../../store";
import { selectUserFavourites } from "../../store/selectors/user/favourites";
import { assetsAPI } from "../../api/assets";
import { selectActiveTab } from "../../store/selectors/trade";
import { setActiveTab } from "../../store/reducers/trade";
import { setAssets } from "../../store/reducers/assets";
import useUserId from "../../hooks/userUserId";

import search from "../../icons/toolbar-search.svg";

import "./RatingToolbar.scss";

const tabs = [
  { id: 2, title: "All", name: "all" },
  { id: 1, title: "Week", name: "week" },
  { id: 3, title: "Month", name: "month" },
  // { id: 4, title: "Down", name: "down" },
  // { id: 5, title: "Up", name: "up" },
];

interface Props {
  searchValue?: string;
  setSearchValue?: any;
  className?: string;
}

export const RatingToolbar = ({
  searchValue,
  setSearchValue,
  className,
}: Props) => {
  const [isVisibleSearch, setIsVisibleSearch] = useState(true);
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const favourites = useSelector(selectUserFavourites);
  const userId = useUserId();

  // const toggleIsVisibleSearch = () => {
  //   setIsVisibleSearch(!isVisibleSearch);
  //   setSearchValue("");
  // };

  const onSetActiveTab = async (name: string) => {
    dispatch(setActiveTab(name));
    postEvent("web_app_trigger_haptic_feedback", {
      type: "selection_change",
    });
  };

  useEffect(() => {
    dispatch(setActiveTab("all"));
  }, [dispatch, setActiveTab]);

  return (
    <div className={classNames("rating-toolbar", className)}>
      <div className="rating-toolbar__header">
        {isVisibleSearch ? (
          <>
            <div className="rating-toolbar__header-loupe">
              <img src={search} alt="поиск" />
            </div>
            <div className="rating-toolbar__header-tabs">
              {tabs.map((item) => (
                <div
                  className={classNames(
                    "rating-toolbar__header-tab",
                    activeTab === item.name ? "active" : ""
                  )}
                  key={item.id}
                  onClick={() => onSetActiveTab(item.name)}
                >
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="rating-toolbar__header-search">
            <input
              placeholder="Search"
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
            <span>Return</span>
          </div>
        )}
      </div>
    </div>
  );
};
