// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.first-screen__balance {
  display: flex;
  margin-top: 200px;
}

@media screen and (max-height: 749px) {
  .first-screen__balance {
    margin-top: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/First/First.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,iBAAA;AACJ;;AAGA;EAEI;IACE,gBAAA;EADJ;AACF","sourcesContent":[".first-screen {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n\n  &__balance {\n    display: flex;\n    margin-top: 200px;\n  }\n}\n\n@media screen and (max-height: 749px) {\n  .first-screen {\n    &__balance {\n      margin-top: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
