import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import cn from "classnames";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

import { userAPI } from "../../api/user";
import { getTotalPrice } from "../../functions/getTotalPrice";
import { useUpdatePositions } from "../../hooks/useUpdatePositions";
import { useUpdateHistory } from "../../hooks/useUpdateHistory";
import { selectUser } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { setUserBalanceLoading } from "../../store/reducers/user/balance";

import checkedIcon from "../../icons/checked.svg";
import closeIcon from "../../icons/close-position.svg";
import closeModalIcon from "../../icons/close-modal.svg";

import "./PositionItem.scss";
import Skeleton from "../Skeleton";
import useUserId from "../../hooks/userUserId";
import classNames from "classnames";
import { setTgUser } from "../../store/reducers/user/ts";
import {
  fetchUserClosedAssets,
  fetchUserOpenAssets,
} from "../../store/actionCreators/user/assets";
import { Drawer, Modal } from "@mui/material";

interface PositionItemProps {
  buyValueUsdt?: string;
  isButton?: boolean;
  currentToken: any;
  currentPrice?: any;
}

const openShortType = "open short position";
const closeLongType = "close long position";
const closeShortType = "close short position";

export const PositionItem = ({
  currentToken,
  isButton = false,
}: PositionItemProps) => {
  const userId = useUserId();
  const { initDataRaw } = retrieveLaunchParams();

  const updatePositions = useUpdatePositions(initDataRaw, "open");
  const updateHistory = useUpdateHistory(initDataRaw);
  const dispatch = useAppDispatch();

  const [usdt, setUsdt] = useState<any>("");
  const [percent, setPercent] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const formatedDate = format(currentToken?.date, "yyyy-MM-dd hh:mm");

  const onClosePositionClick = () => {
    setIsVisibleConfirm(true);
  };

  const onConfirmedClosePositionClick = () => {
    dispatch(setUserBalanceLoading(true));
    userAPI
      .closePosition(
        userId,
        currentToken.asset.symbol,
        currentToken.id,
        initDataRaw,
        tokenPrice
      )
      .then(async () => {
        const amount = currentToken.amount.toString();

        updatePositions();
        updateHistory();
        const { data } = await userAPI.getTgUser(userId, initDataRaw);

        dispatch(setTgUser(data));
        dispatch(fetchUserOpenAssets(userId, initDataRaw, "open"));
        dispatch(fetchUserClosedAssets(userId, initDataRaw, "close"));
        toast.success(
          `You have successfully sold ${currentToken.amount} ${
            currentToken.asset.symbol
          } for ${getTotalPrice(amount, tokenPrice.toString())}`
        );
      })
      .catch(() => toast.error("Error. Try again"))
      .finally(() => {
        setTimeout(() => {
          dispatch(setUserBalanceLoading(false));
        }, 7000);
      });
    setIsVisibleConfirm(false);
  };

  function calculateProfitWithPosition(
    tokenPrice: number,
    priceBuy: number,
    quantity: number,
    position: string
  ) {
    console.log(position);

    const diff =
      position === "open long position"
        ? tokenPrice - priceBuy
        : priceBuy - tokenPrice;

    const profit = diff * quantity;
    const profitPercent = (diff / priceBuy) * 100;

    setPercent(Number(profitPercent.toFixed(2)));
    setUsdt(Number(profit.toFixed(2)));
  }

  useEffect(() => {
    (async function () {
      const res = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${currentToken?.asset.symbol}USDT`
      );
      const data = await res.json();
      setTokenPrice(data.price);
    })();
  }, [currentToken, setTokenPrice]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${currentToken?.asset.symbol.toLowerCase()}usdt@ticker`
    );
    ws.onopen = () => {
      ws.onmessage = (event) => {
        const { data } = JSON.parse(event.data);

        setTokenPrice(Number(data.c));
      };
    };
  }, [currentToken]);

  useEffect(() => {
    tokenPrice &&
      calculateProfitWithPosition(
        tokenPrice,
        currentToken.price,
        currentToken.amount,
        currentToken.type
      );
  }, [tokenPrice, currentToken]);

  return (
    <div className="history-item">
      <div className="history-item__row">
        <div className="history-item__block" style={{ marginTop: 15 }}>
          <div className="history-item__info-coin">
            <img
              className="history-item__info-coin-icon"
              src={`cryptoicons/${currentToken?.asset.symbol.toUpperCase()}.png`}
              alt="коин"
            />
            <div className="history-item__info-coin-symbol">
              {currentToken?.asset.symbol.toUpperCase()}
            </div>
          </div>
          <div
            className={classNames(
              "history-item__type",
              currentToken?.type === openShortType ||
                currentToken?.type === closeLongType ||
                currentToken?.type === closeShortType
                ? "history-item__type_red"
                : ""
            )}
          >
            {currentToken?.type}
          </div>
        </div>
        <div className="history-item__block">
          <div className="history-item__info-price">
            <p>
              <span>{currentToken.amount.toFixed(3)}</span> BTC |{" "}
              <span>{tokenPrice ? Number(tokenPrice).toFixed(3) : "0"}</span>{" "}
              USDT
            </p>
          </div>
          <div
            className={classNames(
              "history-item__info-percent",
              percent < 0 ? "red" : ""
            )}
          >
            <span>{percent > 0 ? `+${usdt}` : usdt} USDT</span> {` `}
            <span>{`(${percent > 0 ? `+${percent}` : percent}%)`}</span>
          </div>
        </div>
        <div className="history-item__block">
          {currentToken?.take_profit || currentToken?.stop_loss ? (
            <div className="history-item__tp">
              TP/SL <img src={checkedIcon} alt="" />
            </div>
          ) : null}
          {currentToken?.take_profit || currentToken?.stop_loss ? (
            <div>
              <p>
                {currentToken?.take_profit ? currentToken?.take_profit : "0"} -{" "}
                {currentToken?.stop_loss ? currentToken?.stop_loss : "0"} USDT
              </p>
            </div>
          ) : null}
        </div>
        <div className="history-item__block">
          <div>
            <p>Comission fee</p>
          </div>
          <div>
            <p>10 USDT</p>
          </div>
        </div>{" "}
        <div className="history-item__block">
          <div>
            <p>Date of issue </p>
          </div>
          <div>
            <p>{formatedDate}</p>
          </div>
        </div>
        <div className="position-item__btn" onClick={onClosePositionClick}>
          <img src={closeIcon} alt="закрыть" />
          <button>Close position</button>
        </div>
        <Drawer
          open={isVisibleConfirm}
          anchor="bottom"
          onClose={() => setIsVisibleConfirm(false)}
        >
          <div className="confirm-modal">
            <div className="confirm-modal__line">
              <div></div>
            </div>
            <img
              src={closeModalIcon}
              alt=""
              className="confirm-modal__close"
              onClick={() => setIsVisibleConfirm(false)}
            />
            <h4 className="confirm-modal__title">
              Are you sure you want to close this position?
            </h4>
            <div
              className="confirm-modal__btn position-item__btn"
              onClick={onConfirmedClosePositionClick}
            >
              <img src={closeIcon} alt="закрыть" />
              <button>Close position</button>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};
