import { motion } from "framer-motion";
import { OnboardingHeader } from "../../Header";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";
import { Steps } from "../../Steps";
import { animationCenter } from "../../../../utils/animation";
import "./Second.scss";

export const SecondScreen = () => {
  return (
    <motion.div 
      className="second-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="second-screen__header">
        <OnboardingHeader />
      </div>
      <motion.div custom={1.5} variants={animationCenter} className="second-screen__balance">
        <BalanceProfitCard />
        <BalanceProfitRemainingCard />
      </motion.div>
      <div className="onboarding__footer">
        <Steps
          path="/onboarding/3"
          title="Balance"
          subtitle="Проверяйте общий и обычный баланс"
          page="1"
        />
      </div>
    </motion.div>
  );
};
