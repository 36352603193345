import React from "react";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";

import { selectIsVisibleInfoModal } from "../../store/selectors/modals";

import close from "../../icons/close-icon.png";
import graph from "../../icons/info-modal-img.png";

import "./InfoModal.scss";
import { useAppDispatch } from "../../store";
import { setIsVisibleInfoModal } from "../../store/reducers/modals";

export const InfoModal = () => {
  const isVisibleInfoModal = useSelector(selectIsVisibleInfoModal);
  const dispatch = useAppDispatch();

  const onClickClose = () => {
    dispatch(setIsVisibleInfoModal(false));
  };

  return (
    <Drawer anchor="bottom" open={isVisibleInfoModal}>
      <div className="info-modal">
        <div className="info-modal__main">
          <button>
            <img
              src={close}
              alt=""
              className="trade-modal__close"
              onClick={onClickClose}
            />
          </button>
          <p>
            Market order is immediately matched to the best available market
            price.
          </p>
          <img src={graph} alt="график" className="info-modal__graph" />
          <p>
            Lorem ipsum dolor sit amet consectetur. Gravida interdum a eu
            bibendum lectus amet quam turpis. Netus id cras dui quis iaculis
            leo. Erat ac urna nibh viverra id sed aliquam posuere. Porttitor.
          </p>
        </div>
      </div>
    </Drawer>
  );
};
