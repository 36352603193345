import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userBalanceState {
  totalBalance: number;
  hasOpenedPositions: boolean;
  isLoading: boolean;
  currentValue: any;
  percentChange: any;
  profitChange: any;
}

const initialState: userBalanceState = {
  totalBalance: 0,
  hasOpenedPositions: false,
  isLoading: false,
  currentValue: 0,
  percentChange: 0,
  profitChange: 0,
};

export const userBalanceSlice = createSlice({
  name: "userBalance",
  initialState,
  reducers: {
    setUserTotalBalance(state, action: PayloadAction<number>) {
      state.totalBalance = action.payload;
    },
    setHasOpenedPositions(state, action: PayloadAction<boolean>) {
      state.hasOpenedPositions = action.payload;
    },
    setUserBalanceLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCurrentValue(state, action: PayloadAction<any>) {
      state.currentValue = action.payload;
    },
    setPercentChange(state, action: PayloadAction<any>) {
      state.percentChange = action.payload;
    },
    setProfitChange(state, action: PayloadAction<any>) {
      state.profitChange = action.payload;
    },
  },
});

export const {
  setUserTotalBalance,
  setHasOpenedPositions,
  setUserBalanceLoading,
  setCurrentValue,
  setPercentChange,
  setProfitChange,
} = userBalanceSlice.actions;
export const userBalanceReducer = userBalanceSlice.reducer;
