import React, { useEffect, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { assetsAPI } from "../../api/assets";
import { useAppDispatch } from "../../store";
import {
  selectIsShowingPositions,
  selectUserPositions,
} from "../../store/selectors/user/positions";
import { setUserPositions } from "../../store/reducers/user/positions";
import useUserId from "../../hooks/userUserId";
import { animationTop } from "../../utils/animation";

import { UserBalance } from "../UserBalance";
import AssetsBar from "../AssetsBar";

import historyIcon from "../../icons/history.svg";

import "./MainInfo.scss";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { setIsVisibleHistoryModal } from "../../store/reducers/modals";
import { HistoryModal } from "../HistoryModal";

export const MainInfo = () => {
  const userId = useUserId();
  const dispatch = useAppDispatch();
  const { initDataRaw } = retrieveLaunchParams();

  const positions = useSelector(selectUserPositions);
  const isShowingPositions = useSelector(selectIsShowingPositions);

  console.log(positions);

  const onHistoryClick = () => {
    dispatch(setIsVisibleHistoryModal(true));
  };

  useEffect(() => {
    const someArray: any = [];
    (async () => {
      const { data } = await assetsAPI.getPositions(
        userId,
        initDataRaw,
        "open"
      );

      data.map((item: any) => {
        const token: any = item;

        if (Number(token.amount) > 0) {
          someArray.push(token);
        }
      });
      dispatch(setUserPositions(someArray));
    })();
  }, [dispatch]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className={cn("main-info", { "main-info_opened": isShowingPositions })}
    >
      <div className="main-info__wrap">
        <div className="main-info__head">
          <motion.div
            custom={2}
            variants={animationTop}
            style={{ width: "100%" }}
          >
            <UserBalance />
          </motion.div>
        </div>
        <motion.div
          className="main-info__history widget-card"
          onClick={onHistoryClick}
          custom={2.2}
          variants={animationTop}
        >
          <img src={historyIcon} alt="" />
          <p>Open positions / History</p>
        </motion.div>
      </div>
      <HistoryModal />
    </motion.div>
  );
};
