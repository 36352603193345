import { useCallback } from "react";
import { useSelector } from "react-redux";

import { assetsAPI } from "../api/assets";
import { selectUser } from "../store/selectors/user";
import { useAppDispatch } from "../store";
import { setUserHistory } from "../store/reducers/user/history";
import useUserId from "./userUserId";

export const useUpdateHistory = (initDataRaw: any) => {
  // const user = useSelector(selectUser);
  const userId = useUserId();
  const dispatch = useAppDispatch();
  const updateHistory = useCallback(async () => {
    const { data } = await assetsAPI.getHistory(userId, initDataRaw);
    dispatch(setUserHistory(data));
  }, [setUserHistory]);
  return updateHistory;
};
