// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-modal {
  height: 100vh;
  padding: 30px 15px 30px 15px;
  position: relative;
  z-index: 999;
}
.history-modal__main {
  margin-top: 30px;
}
.history-modal__main-tabs {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0dfdf;
}
.history-modal__main-tab {
  background: #e7e7e7;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
}
.history-modal__main-tab:first-child {
  margin-right: 10px;
}
.history-modal__main-tab.active {
  background-color: #8c5af8;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/HistoryModal/HistoryModal.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4BAAA;EACA,kBAAA;EACA,YAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,gCAAA;AAGN;AADI;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AAGN;AAFM;EACE,kBAAA;AAIR;AAFM;EACE,yBAAA;EACA,WAAA;AAIR","sourcesContent":[".history-modal {\n  height: 100vh;\n  padding: 30px 15px 30px 15px;\n  position: relative;\n  z-index: 999;\n  &__main {\n    margin-top: 30px;\n    &-tabs {\n      display: flex;\n      align-items: center;\n      padding-bottom: 10px;\n      border-bottom: 1px solid #e0dfdf;\n    }\n    &-tab {\n      background: #e7e7e7;\n      border-radius: 20px;\n      padding: 10px;\n      font-size: 14px;\n      &:first-child {\n        margin-right: 10px;\n      }\n      &.active {\n        background-color: #8c5af8;\n        color: #fff;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
