import React from "react";
import { useSelector } from "react-redux";

import { selectUserBalanceLoading } from "../../store/selectors/user/balance";

import BalanceProfit from "../BalanceProfit";

import "./UserBalance.scss";
import { CircularProgress } from "@mui/material";

export const UserBalance = () => {
  const isLoading = useSelector(selectUserBalanceLoading);

  return (
    <div className="user-balance">
      <>
        {!isLoading ? (
          <>
            <BalanceProfit />
          </>
        ) : (
          <div
            style={{ minHeight: 102, display: "flex", alignItems: "center" }}
          >
            <CircularProgress color="success" size={70} thickness={2} />
          </div>
        )}
      </>
    </div>
  );
};
