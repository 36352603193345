import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@mui/material";

import { selectIsVisibleHistoryModal } from "../../store/selectors/modals";

import arrowLeft from "../../icons/arrow-left.svg";
import trade from "../../icons/trade-white.svg";

import "./HistoryModal.scss";
import { useAppDispatch } from "../../store";
import { setIsVisibleHistoryModal } from "../../store/reducers/modals";
import { History } from "../History";
import classNames from "classnames";
import { PositionItem } from "../PositionItem";
import { selectUserPositions } from "../../store/selectors/user/positions";
import { useNavigate } from "react-router-dom";

export const HistoryModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isVisible = useSelector(selectIsVisibleHistoryModal);
  const positions = useSelector(selectUserPositions);

  const [activeTab, setActiveTab] = useState<"positions" | "history">(
    "positions"
  );

  const onArrowClick = () => {
    dispatch(setIsVisibleHistoryModal(false));
  };
  return (
    <Drawer open={isVisible} anchor="bottom">
      <div className="bg"></div>
      <div className="history-modal">
        <img src={arrowLeft} alt="выход" onClick={onArrowClick} />
        <div className="history-modal__main widget-card">
          <div className="history-modal__main-tabs">
            <div
              className={classNames(
                "history-modal__main-tab",
                activeTab === "positions" ? "active" : ""
              )}
              onClick={() => setActiveTab("positions")}
            >
              Open positions
            </div>
            <div
              className={classNames(
                "history-modal__main-tab",
                activeTab === "history" ? "active" : ""
              )}
              onClick={() => setActiveTab("history")}
            >
              History
            </div>
          </div>
          <History isVisible={activeTab === "history" ? true : false} />
          {activeTab === "positions" && (
            <div className={classNames("main-info__positions-list")}>
              {positions.length > 0 ? (
                positions.map((position: any) => {
                  return (
                    <PositionItem
                      key={position.id}
                      isButton={true}
                      currentToken={position}
                      buyValueUsdt={position.usdt.toString()}
                    />
                  );
                })
              ) : (
                <div className="main-info__empty">
                  <h2>Start trading to view open positions</h2>
                  <div
                    className="main-info__empty-trade"
                    onClick={() => navigate("/trade")}
                  >
                    <img src={trade} alt="" />
                    Trade
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
