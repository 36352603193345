import axios from "axios";

const earn = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
});

export const earnAPI = {
  fetchDailyRewards: (userId: number, initDataRaw: any) => {
    return earn.post(
      `/daily/check_daily_awards?user_id=${userId}`,
      {},
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  claimDailyReward: (userId: number, day: string, initDataRaw: any) => {
    return earn.post(
      `/daily/check_daily_awards/claim?user_id=${userId}&day=${day}`,
      {},
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  fetchSubRewards: (userId: number, initDataRaw: any) => {
    return earn.get(`/sub_rewards/collected_rewards/${userId}`, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  claimSubReward: (userId: number, task: string, initDataRaw: any) => {
    return earn.get(`/sub_rewards/get_award/${userId}?task=${task}`, {
      headers: {
        Authorization: `tma ${initDataRaw}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
