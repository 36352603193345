import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import {
  selectHasOpenedPositions,
  selectUserTotalBalance,
} from "../../store/selectors/user/balance";

import "./BalanceProfit.scss";
import { selectTgUser } from "../../store/selectors/user/tg";
import { BalanceProfitCard } from "../BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../BalanceProfitRemainingCard";
import { selectUserClosedAssets } from "../../store/selectors/user/assets";
import { useAppDispatch } from "../../store";
import { fetchUserClosedAssets } from "../../store/actionCreators/user/assets";
import useUserId from "../../hooks/userUserId";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import {
  setCurrentValue,
  setPercentChange,
  setProfitChange,
} from "../../store/reducers/user/balance";

const BalanceProfit = () => {
  const dispatch = useAppDispatch();
  const tgUser = useSelector(selectTgUser);
  const userId = useUserId();
  const { initDataRaw } = retrieveLaunchParams();

  const totalBalance = useSelector(selectUserTotalBalance);
  const hasOpenedPositions = useSelector(selectHasOpenedPositions);
  const userClosedAssets = useSelector(selectUserClosedAssets);

  // const [percentChange, setPercentChange] = useState<any>();
  // const [profitChange, setProfitChange] = useState<any>();
  // const [currentValue, setCurrentValue] = useState<any>(0);

  // useEffect(() => {
  //   const percent: number = userClosedAssets.reduce(
  //     (total, item: any) => total + item.pnl,
  //     0
  //   );

  //   const price_diff = userClosedAssets.reduce(
  //     (total, item: any) => total + item.price_diff,
  //     0
  //   );

  //   dispatch(setPercentChange(percent));
  //   dispatch(setProfitChange(price_diff));
  //   // setPercentChange(percent);
  //   // setProfitChange(price_diff);
  // }, [userClosedAssets]);

  useEffect(() => {
    if (totalBalance) {
      const localAmount = hasOpenedPositions
        ? Number(totalBalance) + tgUser?.usdt
        : tgUser?.usdt;

      const amount = ((localAmount - 1000000) / 1000000) * 100;

      dispatch(setCurrentValue(localAmount.toFixed(6)));

      // setCurrentValue(localAmount.toFixed(6));
      // setPercentChange(amount.toFixed(2));
      // setProfitChange(localAmount - 1000000);
    }
  }, [totalBalance, hasOpenedPositions, tgUser]);

  // useEffect(() => {
  //   dispatch(fetchUserClosedAssets(userId, initDataRaw, "close"));
  // }, [dispatch]);

  return (
    <>
      <div className="balance-wrapper">
        <BalanceProfitCard
          // currentValue={currentValue}
          // profitChange={profitChange}
          // percentChange={percentChange}
          style={{ fontSize: 40 }}
        />
      </div>
      {/* <BalanceProfitRemainingCard /> */}
    </>
  );
};

export default BalanceProfit;
