// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  min-height: 100vh;
}
.dashboard__info {
  display: flex;
  justify-content: space-between;
}
.dashboard__info span {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}
.dashboard__toolbar {
  position: sticky;
  top: 30px;
  z-index: 99999999;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboard__asset-header {
  color: #000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  color: #595959;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;AAEJ;AADI;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AAGN;AAAE;EACE,gBAAA;EACA,SAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".dashboard {\n  min-height: 100vh;\n  &__info {\n    display: flex;\n    justify-content: space-between;\n    span {\n      color: #000;\n      font-size: 24px;\n      font-weight: 500;\n    }\n  }\n  &__toolbar {\n    position: sticky;\n    top: 30px;\n    z-index: 99999999;\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n  &__asset-header {\n    color: #000;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    font-size: 14px;\n    color: #595959;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
