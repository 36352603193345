import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Steps } from "../../Steps";
import { OnboardingHeader } from "../../Header";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import "./Eights.scss";

export const EightsScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);
  };
  return (
    <motion.div
      className="eights-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="eights-screen__header">
        <OnboardingHeader />
      </div>
      <motion.div
        custom={1.5}
        variants={animationCenter}
        className="eights-screen__coin"
      >
        <TradeSelect />
      </motion.div>
      <div className="onboarding__footer">
        <motion.div custom={1.5} variants={animationCenter}>
          <Steps
            path="/onboarding/9"
            title="Coins"
            subtitle="Проверяйте общий и обычный баланс"
            page="4"
            withoutButton={true}
          />
        </motion.div>
        <div className="onboarding__btn">
          <button onClick={() => onNavigate("/onboarding/9")}>
            Start trading
          </button>
        </div>
      </div>
    </motion.div>
  );
};
