import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import "./Preloader.scss";

export const Preloader = () => {
  function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#FF0000" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    );
  }
  return (
    <div className="preloader">
      <GradientCircularProgress />
      <h1 className="preloader__title">Welcome back!</h1>
    </div>
  );
};
