import { motion } from "framer-motion";
import { OnboardingHeader } from "../../Header";
import { Steps } from "../../Steps";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";
import "./Seventh.scss";

export const SeventhScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);

    setTimeout(() => {
      navigate("/onboarding/9");
    }, 1000);
  };
  return (
    <motion.div
      className="seventh-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="seventh-screen__header">
        <OnboardingHeader />
      </div>
      <motion.div variants={animationCenter} custom={1.5}>
        <div className="seventh-screen__coin">
          <TradeSelect />
        </div>
      </motion.div>
      <div className="onboarding__footer">
        <motion.div variants={animationCenter} custom={1.5}>
          <Steps
            path="/onboarding/8"
            title="Coins"
            subtitle="Проверяйте общий и обычный баланс"
            page="4"
            withoutButton={true}
          />
        </motion.div>
        <div className="onboarding__btn">
          <button onClick={() => onNavigate("/onboarding/8")}>continue</button>
        </div>
      </div>
    </motion.div>
  );
};
