import { motion } from "framer-motion";
import { OnboardingHeader } from "../../Header";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";
import { Steps } from "../../Steps";
import { animationCenter } from "../../../../utils/animation";
import "./First.scss";

export const FirstScreen = () => {
  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className="first-screen"
    >
      <div className="first-screen__header">
        <OnboardingHeader />
      </div>
      <div className="first-screen__main">
        <motion.div custom={1} variants={animationCenter} className="first-screen__balance">
          <BalanceProfitCard className="first-screen-profit" />
          <BalanceProfitRemainingCard className="first-screen-profit__remaining" />
        </motion.div>
      </div>
      <div className="onboarding__footer">
        <motion.div custom={1} variants={animationCenter}>
          <Steps
            path="/onboarding/2"
            title="Balance"
            subtitle="Проверяйте общий и обычный баланс"
            page="1"
          />
        </motion.div>
      </div>
    </motion.div>
  );
};
