// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.third-screen__balance {
  display: flex;
}
.third-screen-tab {
  box-shadow: -5px -4px 20px 0px rgba(129, 161, 224, 0.3411764706);
}
.third-screen__tabs {
  display: flex;
  flex-direction: column;
  margin-top: 200px;
}
@media screen and (max-width: 362px) {
  .third-screen__tabs {
    margin-top: 30px;
  }
}
@media screen and (max-height: 668px) {
  .third-screen__tabs {
    margin-top: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Third/Third.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,gEAAA;AAAJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;AACI;EAJF;IAKI,gBAAA;EAEJ;AACF;AADI;EAPF;IAQI,gBAAA;EAIJ;AACF","sourcesContent":[".third-screen {\n  &__balance {\n    display: flex;\n  }\n  &-tab {\n    box-shadow: -5px -4px 20px 0px #81a1e057;\n  }\n  &__tabs {\n    display: flex;\n    flex-direction: column;\n    margin-top: 200px;\n    @media screen and (max-width: 362px) {\n      margin-top: 30px;\n    }\n    @media screen and (max-height: 668px) {\n      margin-top: 30px;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
